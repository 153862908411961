<template>
  <div class="container">
    <div class="box">
      <div class="info-wrap">
        <p class="title">주요 상태</p>
        <hr />
        <div class="container">
          <div class="box" style="background: #e3d29480; border: 1px solid #e3d294">
            <p>룸온도</p>
            <p>
              <template v-if="configData != null">{{ configData.v7 }}</template>
              <template v-else>-</template>
              ℃
            </p>
          </div>
          <div class="box" style="background: #e3d29480; border: 1px solid #e3d294">
            <p>과열도</p>
            <p>
              <template v-if="configData != null">{{ configData.v6 }}</template>
              <template v-else>-</template>
              ℃
            </p>
          </div>
        </div>
        <div class="container">
          <div class="box" style="background: #84c8c080; border: 1px solid #84c8c0">
            <p>고압</p>
            <p>
              <template v-if="configData != null">{{ configData.v5 }}</template>
              <template v-else>-</template>
              bar
            </p>
          </div>
          <div class="box" style="background: #84c8c080; border: 1px solid #84c8c0">
            <p>저압</p>
            <p>
              <template v-if="configData != null">{{ configData.v4 }}</template>
              <template v-else>-</template>
              bar
            </p>
          </div>
        </div>
        <div class="container">
          <div class="box">
            <p>응축가스 온도</p>
            <p>
              <template v-if="configData != null">{{ configData.v2 }}</template>
              <template v-else>-</template>
              ℃
            </p>
          </div>
          <div class="box">
            <p>외기온도</p>
            <p>
              <template v-if="configData != null">{{ configData.v3 }}</template>
              <template v-else>-</template>
              ℃
            </p>
          </div>
        </div>
        <div class="container">
          <div class="box">
            <p>EVA 입구 온도</p>
            <p>
              <template v-if="configData != null">{{ configData.v8 }}</template>
              <template v-else>-</template>
              ℃
            </p>
          </div>
          <div class="box">
            <p>EVA 출구 온도</p>
            <p>
              <template v-if="configData != null">{{ configData.v11 }}</template>
              <template v-else>-</template>
              ℃
            </p>
          </div>
        </div>
        <div class="container">
          <div class="box">
            <p>압축기 헤더 온도</p>
            <p>
              <template v-if="configData != null">{{ configData.v9 }}</template>
              <template v-else>-</template>
              ℃
            </p>
          </div>
          <div class="box">
            <p>토출가스 온도</p>
            <p>
              <template v-if="configData != null">{{ configData.v10 }}</template>
              <template v-else>-</template>
              ℃
            </p>
          </div>
        </div>

        <div class="container">
          <div class="box">
            <p>실외기팬 출력</p>
            <p>
              <template v-if="configData != null">{{ configData.v12 }}</template>
              <template v-else>-</template>
              %
            </p>
          </div>
          <div class="box">
            <p>메인 EEV 개도</p>
            <p>
              <template v-if="configData != null">{{ configData.v13 }}</template>
              <template v-else>-</template>
              %
            </p>
          </div>
        </div>
      </div>
      <div class="info-wrap">
        <p class="title">경보 상태</p>
        <hr />
        <div class="container">
          <div class="box">
            <p>실내기 흡입이상 예측</p>
            <template v-if="configData != null">
              <p v-if="configData.v15 === '0'" class="state blue">정상</p>
              <p v-else class="state red">이상</p>
            </template>
            <template v-else><p>-</p></template>
          </div>
          <div class="box">
            <p>실외기 흡입이상 예측</p>
            <template v-if="configData != null">
              <p v-if="configData.v16 === '0'" class="state blue">정상</p>
              <p v-else class="state red">이상</p>
            </template>
            <template v-else><p>-</p></template>
          </div>
        </div>
        <div class="container">
          <div class="box">
            <p>냉매이상 예측</p>
            <template v-if="configData != null">
              <p v-if="configData.v17 === '0'" class="state blue">정상</p>
              <p v-else class="state red">이상</p>
            </template>
            <template v-else><p>-</p></template>
          </div>
          <div class="box" style="border: none; background: none"></div>
        </div>
      </div>
      <div class="info-wrap">
        <p class="title" style="font-size: 18px; font-weight: 700; color: #84c8c0; padding-left: 10px">실시간 트랜드</p>
        <hr style="width: 100%; height: 1px; margin: 10px 0; border: 0; background: #343b4f" />
        <AreaChart100301 ref="chartRef" />
      </div>
    </div>
  </div>

  <DeviceSettingModal v-if="isModalViewed && configData != null" :device-i-d="configData.deviceID" :modal-data-type="modalDataType" @close-modal="isModalViewed = false" @change-success="changeData" />
</template>
<script>
import DeviceSettingModal from '@/components/pc/dashboard/config/modal/DeviceSettingModal.vue'
import AreaChart100301 from '@/components/pc/dashboard/chart/AreaChart100301.vue'

export default {
  name: 'DetailType100101',
  components: { AreaChart100301, DeviceSettingModal },
  props: {
    configData: {
      type: Object,
      required: false,
      default: null,
    },
    chartData: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      editable: true,
      isModalViewed: false,
      modalDataType: 0,
    }
  },
  watch: {
    chartData() {
      this.$refs.chartRef.dataChange(this.chartData)
    },
  },
  methods: {
    changeData() {},
    showSettingModal(type) {
      if (this.editable) {
        this.modalDataType = type
        this.isModalViewed = true
      }
    },
  },
}
</script>
