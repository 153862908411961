import axios from '@/utils/axios_interceptor'

function requestGetMacAddress() {
  return axios.get('/getMacAddress')
}

function requestGetServerInfo(){
  return axios.get('/getServerInfo')
}

export { requestGetMacAddress , requestGetServerInfo}
