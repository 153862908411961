<template>
  <div class="container">
    <div class="box">
      <div class="info-wrap">
        <p class="title">주요 상태</p>
        <hr />
        <div class="container">
          <div class="box" style="background: #e3d29480; border: 1px solid #e3d294">
            <p>현재온도</p>
            <p>
              <template v-if="configData != null">{{ configData.v1 / 10 }}</template>
              <template v-else>-</template>
              ℃
            </p>
          </div>
          <div class="box" style="background: #84c8c080; border: 1px solid #84c8c0">
            <p>현재습도</p>
            <p>
              <template v-if="configData != null">{{ configData.v2 }}</template>
              <template v-else>-</template>
              %
            </p>
          </div>
        </div>
        <div class="container">
          <div class="box">
            <p>팬 운전</p>
            <template v-if="configData != null">
              <p v-if="configData.v10 === '0'" class="state grey">정지</p>
              <p v-else class="state green">동작</p>
            </template>
            <template v-else> -</template>
          </div>
          <div class="box">
            <p>냉방 운전</p>
            <template v-if="configData != null">
              <p v-if="configData.v11 === '0'" class="state grey">정지</p>
              <p v-else class="state green">동작</p>
            </template>
            <template v-else> -</template>
          </div>
        </div>
        <div class="container">
          <div class="box">
            <p>난방 운전</p>
            <template v-if="configData != null">
              <p v-if="configData.v12 === '0'" class="state grey">정지</p>
              <p v-else class="state green">동작</p>
            </template>
            <template v-else> -</template>
          </div>
          <div class="box">
            <p>가습 운전</p>
            <template v-if="configData != null">
              <p v-if="configData.v13 === '0'" class="state grey">정지</p>
              <p v-else class="state green">동작</p>
            </template>
            <template v-else> -</template>
          </div>
        </div>
        <div class="container">
          <div class="box">
            <p>제습 운전</p>
            <template v-if="configData != null">
              <p v-if="configData.v14 === '0'" class="state grey">정지</p>
              <p v-else class="state green">동작</p>
            </template>
            <template v-else> -</template>
          </div>
          <div class="box" style="border: none; background: none"></div>
        </div>
      </div>
      <div class="info-wrap">
        <p class="title">접점 출력</p>
        <hr />
        <div class="container">
          <div class="box">
            <p>난방SSR</p>
            <template v-if="configData != null">
              <p v-if="configData.v15 === '0'" class="state grey">정지</p>
              <p v-else class="state green">동작</p>
            </template>
            <template v-else> -</template>
          </div>
          <div class="box">
            <p>난방히터1</p>
            <template v-if="configData != null">
              <p v-if="configData.v16 === '0'" class="state grey">정지</p>
              <p v-else class="state green">동작</p>
            </template>
            <template v-else> -</template>
          </div>
        </div>
        <div class="container">
          <div class="box">
            <p>난방히터2</p>
            <template v-if="configData != null">
              <p v-if="configData.v17 === '0'" class="state grey">정지</p>
              <p v-else class="state green">동작</p>
            </template>
            <template v-else> -</template>
          </div>
          <div class="box">
            <p>난방히터3</p>
            <template v-if="configData != null">
              <p v-if="configData.v18 === '0'" class="state grey">정지</p>
              <p v-else class="state green">동작</p>
            </template>
            <template v-else> -</template>
          </div>
        </div>
        <div class="container">
          <div class="box">
            <p>난방히터4</p>
            <template v-if="configData != null">
              <p v-if="configData.v19 === '0'" class="state grey">정지</p>
              <p v-else class="state green">동작</p>
            </template>
            <template v-else> -</template>
          </div>
          <div class="box">
            <p>압축기1</p>
            <template v-if="configData != null">
              <p v-if="configData.v20 === '0'" class="state grey">정지</p>
              <p v-else class="state green">동작</p>
            </template>
            <template v-else> -</template>
          </div>
        </div>
        <div class="container">
          <div class="box">
            <p>압축기2</p>
            <template v-if="configData != null">
              <p v-if="configData.v21 === '0'" class="state grey">정지</p>
              <p v-else class="state green">동작</p>
            </template>
            <template v-else> -</template>
          </div>
          <div class="box">
            <p>SOL1</p>
            <template v-if="configData != null">
              <p v-if="configData.v22 === '0'" class="state grey">정지</p>
              <p v-else class="state green">동작</p>
            </template>
            <template v-else> -</template>
          </div>
        </div>
        <div class="container">
          <div class="box">
            <p>SOL2</p>
            <template v-if="configData != null">
              <p v-if="configData.v23 === '0'" class="state grey">정지</p>
              <p v-else class="state green">동작</p>
            </template>
            <template v-else> -</template>
          </div>
          <div class="box">
            <p>FAN</p>
            <template v-if="configData != null">
              <p v-if="configData.v24 === '0'" class="state grey">정지</p>
              <p v-else class="state green">동작</p>
            </template>
            <template v-else> -</template>
          </div>
        </div>
        <div class="container">
          <div class="box">
            <p>통합경보</p>
            <template v-if="configData != null">
              <p v-if="configData.v25 === '0'" class="state grey">정지</p>
              <p v-else class="state green">동작</p>
            </template>
            <template v-else> -</template>
          </div>
          <div class="box">
            <p>압축기4</p>
            <template v-if="configData != null">
              <p v-if="configData.v26 === '0'" class="state grey">정지</p>
              <p v-else class="state green">동작</p>
            </template>
            <template v-else> -</template>
          </div>
        </div>
        <div class="container">
          <div class="box">
            <p>HU2</p>
            <template v-if="configData != null">
              <p v-if="configData.v27 === '0'" class="state grey">정지</p>
              <p v-else class="state green">동작</p>
            </template>
            <template v-else> -</template>
          </div>
          <div class="box">
            <p>HU1</p>
            <template v-if="configData != null">
              <p v-if="configData.v28 === '0'" class="state grey">정지</p>
              <p v-else class="state green">동작</p>
            </template>
            <template v-else> -</template>
          </div>
        </div>
        <div class="container">
          <div class="box">
            <p>HU/SOL</p>
            <template v-if="configData != null">
              <p v-if="configData.v29 === '0'" class="state grey">정지</p>
              <p v-else class="state green">동작</p>
            </template>
            <template v-else> -</template>
          </div>
          <div class="box">
            <p>압축기3</p>
            <template v-if="configData != null">
              <p v-if="configData.v30 === '0'" class="state grey">정지</p>
              <p v-else class="state green">동작</p>
            </template>
            <template v-else> -</template>
          </div>
        </div>
        <div class="container">
          <div class="box">
            <p>SOL3</p>
            <template v-if="configData != null">
              <p v-if="configData.v31 === '0'" class="state grey">정지</p>
              <p v-else class="state green">동작</p>
            </template>
            <template v-else> -</template>
          </div>
          <div class="box" style="border: none; background: none"></div>
        </div>
      </div>
      <div class="info-wrap">
        <p class="title">주요 설정</p>
        <hr />
        <div class="container">
          <div class="box" :class="{ editable: editable }" @click="showSettingModal(0)">
            <p>온도 설정</p>
            <p>
              <template v-if="configData != null">{{ configData.v77 / 10 }}</template>
              <template v-else>-</template>
              ℃
            </p>
          </div>
          <div class="box" :class="{ editable: editable }" @click="showSettingModal(1)">
            <p>습도 설정</p>
            <p>
              <template v-if="configData != null">{{ configData.v78 }}</template>
              <template v-else>-</template>
              %
            </p>
          </div>
        </div>
        <div class="container">
          <div class="box" :class="{ editable: editable }" @click="showSettingModal(2)">
            <p>냉각 편차</p>
            <p>
              <template v-if="configData != null">{{ configData.v79 / 10 }}</template>
              <template v-else>-</template>
              ℃
            </p>
          </div>
          <div class="box" :class="{ editable: editable }" @click="showSettingModal(3)">
            <p>제습 편차</p>
            <p>
              <template v-if="configData != null">{{ configData.v80 }}</template>
              <template v-else>-</template>
              %
            </p>
          </div>
        </div>
        <div class="container">
          <div class="box" :class="{ editable: editable }" @click="showSettingModal(4)">
            <p>난방 편차</p>
            <p>
              <template v-if="configData != null">{{ configData.v82 / 10 }}</template>
              <template v-else>-</template>
              ℃
            </p>
          </div>
          <div class="box" :class="{ editable: editable }" @click="showSettingModal(5)">
            <p>가습 편차</p>
            <p>
              <template v-if="configData != null">{{ configData.v81 }}</template>
              <template v-else>-</template>
              %
            </p>
          </div>
        </div>
        <div class="container">
          <div class="box">
            <p>운전 정지</p>
            <template v-if="configData != null">
              <p v-if="configData.v76 === '0'" class="state grey">정지</p>
              <p v-else class="state green">동작</p>
            </template>
          </div>
          <div class="box" style="border: none; background: none"></div>
        </div>
      </div>
      <div class="info-wrap">
        <p class="title">세부 설정</p>
        <hr />
        <div class="container">
          <div class="box" :class="{ editable: editable }" @click="showSettingModal(6)">
            <p>정전 복귀</p>
            <p>
              <template v-if="configData != null">{{ configData.v87 }}</template>
              <template v-else>-</template>
              초
            </p>
          </div>
          <div class="box" :class="{ editable: editable }" @click="showSettingModal(7)">
            <p>팬 정지 지연</p>
            <p>
              <template v-if="configData != null">{{ configData.v86 }}</template>
              <template v-else>-</template>
              초
            </p>
          </div>
        </div>
        <div class="container">
          <div class="box" :class="{ editable: editable }" @click="showSettingModal(8)">
            <p>압축 지연1</p>
            <p>
              <template v-if="configData != null">{{ configData.v83 }}</template>
              <template v-else>-</template>
              초
            </p>
          </div>
          <div class="box" :class="{ editable: editable }" @click="showSettingModal(9)">
            <p>압축 지연2</p>
            <p>
              <template v-if="configData != null">{{ configData.v84 }}</template>
              <template v-else>-</template>
              초
            </p>
          </div>
        </div>
        <div class="container">
          <div class="box" :class="{ editable: editable }" @click="showSettingModal(10)">
            <p>압축 지연3</p>
            <p>
              <template v-if="configData != null">{{ configData.v85 }}</template>
              <template v-else>-</template>
              초
            </p>
          </div>
          <div class="box" :class="{ editable: editable }" @click="showSettingModal(11)">
            <p>운전 모드</p>
            <p>
              <template v-if="configData != null && configData.v88 === '0'">자체</template>
              <template v-else-if="configData != null && configData.v88 === '1'">원격(접점운전)</template>
              <template v-else-if="configData != null && configData.v88 === '2'">스케줄( STHAV-ST이상 )</template>
              <template v-else>-</template>
            </p>
          </div>
        </div>
      </div>
      <div class="info-wrap">
        <p class="title">실시간 트랜드</p>
        <hr />
        <div class="container">
          <div class="box">
            <p>AO1 출력</p>
            <p>
              <template v-if="configData != null">{{ configData.v93 }}</template>
              <template v-else>-</template>
              %
            </p>
          </div>
          <div class="box">
            <p>AO2 출력</p>
            <p>
              <template v-if="configData != null">{{ configData.v94 }}</template>
              <template v-else>-</template>
              %
            </p>
          </div>
        </div>
        <div class="container">
          <div class="box">
            <p>AO3 출력</p>
            <p>
              <template v-if="configData != null">{{ configData.v95 }}</template>
              <template v-else>-</template>
              %
            </p>
          </div>
          <div class="box">
            <p>AO4 출력</p>
            <p>
              <template v-if="configData != null">{{ configData.v96 }}</template>
              <template v-else>-</template>
              %
            </p>
          </div>
        </div>
      </div>
      <div class="info-wrap">
        <p class="title">적산 시간</p>
        <hr />
        <div class="container">
          <div class="box">
            <p>장비운전</p>
            <p>60123h</p>
          </div>
          <div class="box">
            <p>압축기1</p>
            <p>60123h</p>
          </div>
        </div>
        <div class="container">
          <div class="box">
            <p>압축기2</p>
            <p>60123h</p>
          </div>
          <div class="box">
            <p>압축기3</p>
            <p>60123h</p>
          </div>
        </div>
        <div class="container">
          <div class="box">
            <p>가습기</p>
            <p>60123h</p>
          </div>
          <div class="box" style="border: none; background: none"></div>
        </div>
      </div>
      <div class="info-wrap">
        <p class="title">경보 상태</p>
        <hr />
        <div class="container">
          <div class="box">
            <p>압축기1 이상</p>
            <template v-if="configData != null">
              <p v-if="configData.v49 === '0'" class="state blue">정상</p>
              <p v-else class="state red">이상</p>
            </template>
            <template v-else><p>-</p></template>
          </div>
          <div class="box">
            <p>압축기2 이상</p>
            <template v-if="configData != null">
              <p v-if="configData.v50 === '0'" class="state blue">정상</p>
              <p v-else class="state red">이상</p>
            </template>
            <template v-else><p>-</p></template>
          </div>
        </div>
        <div class="container">
          <div class="box">
            <p>압축기3 이상</p>
            <template v-if="configData != null">
              <p v-if="configData.v51 === '0'" class="state blue">정상</p>
              <p v-else class="state red">이상</p>
            </template>
            <template v-else><p>-</p></template>
          </div>
          <div class="box">
            <p>저압1 이상</p>
            <template v-if="configData != null">
              <p v-if="configData.v52 === '0'" class="state blue">정상</p>
              <p v-else class="state red">이상</p>
            </template>
            <template v-else><p>-</p></template>
          </div>
        </div>
        <div class="container">
          <div class="box">
            <p>저압2 이상</p>
            <template v-if="configData != null">
              <p v-if="configData.v53 === '0'" class="state blue">정상</p>
              <p v-else class="state red">이상</p>
            </template>
            <template v-else><p>-</p></template>
          </div>
          <div class="box">
            <p>저압3 이상</p>
            <template v-if="configData != null">
              <p v-if="configData.v54 === '0'" class="state blue">정상</p>
              <p v-else class="state red">이상</p>
            </template>
            <template v-else><p>-</p></template>
          </div>
        </div>
        <div class="container">
          <div class="box">
            <p>습도과열기 이상</p>
            <template v-if="configData != null">
              <p v-if="configData.v55 === '0'" class="state blue">정상</p>
              <p v-else class="state red">이상</p>
            </template>
            <template v-else><p>-</p></template>
          </div>
          <div class="box">
            <p style="font-size: 14px">난방/재열히터 이상</p>
            <template v-if="configData != null">
              <p v-if="configData.v56 === '0'" class="state blue">정상</p>
              <p v-else class="state red">이상</p>
            </template>
            <template v-else><p>-</p></template>
          </div>
        </div>
        <div class="container">
          <div class="box">
            <p>공기흐름 이상</p>
            <template v-if="configData != null">
              <p v-if="configData.v57 === '0'" class="state blue">정상</p>
              <p v-else class="state red">이상</p>
            </template>
            <template v-else><p>-</p></template>
          </div>
          <div class="box">
            <p>메인팬 이상</p>
            <template v-if="configData != null">
              <p v-if="configData.v58 === '0'" class="state blue">정상</p>
              <p v-else class="state red">이상</p>
            </template>
            <template v-else><p>-</p></template>
          </div>
        </div>
        <div class="container">
          <div class="box">
            <p>누수감지 이상</p>
            <template v-if="configData != null">
              <p v-if="configData.v59 === '0'" class="state blue">정상</p>
              <p v-else class="state red">이상</p>
            </template>
            <template v-else><p>-</p></template>
          </div>
          <div class="box">
            <p>고온 이상</p>
            <template v-if="configData != null">
              <p v-if="configData.v60 === '0'" class="state blue">정상</p>
              <p v-else class="state red">이상</p>
            </template>
            <template v-else><p>-</p></template>
          </div>
        </div>
        <div class="container">
          <div class="box">
            <p>저온 이상</p>
            <template v-if="configData != null">
              <p v-if="configData.v61 === '0'" class="state blue">정상</p>
              <p v-else class="state red">이상</p>
            </template>
            <template v-else><p>-</p></template>
          </div>
          <div class="box">
            <p>고습 이상</p>
            <template v-if="configData != null">
              <p v-if="configData.v62 === '0'" class="state blue">정상</p>
              <p v-else class="state red">이상</p>
            </template>
            <template v-else><p>-</p></template>
          </div>
        </div>
        <div class="container">
          <div class="box">
            <p>저습 이상</p>
            <template v-if="configData != null">
              <p v-if="configData.v63 === '0'" class="state blue">정상</p>
              <p v-else class="state red">이상</p>
            </template>
            <template v-else><p>-</p></template>
          </div>
          <div class="box">
            <p>하론 이상</p>
            <template v-if="configData != null">
              <p v-if="configData.v64 === '0'" class="state blue">정상</p>
              <p v-else class="state red">이상</p>
            </template>
            <template v-else><p>-</p></template>
          </div>
        </div>
        <div class="container">
          <div class="box">
            <p>필터교체</p>
            <template v-if="configData != null">
              <p v-if="configData.v65 === '0'" class="state blue">정상</p>
              <p v-else class="state red">이상</p>
            </template>
            <template v-else><p>-</p></template>
          </div>
          <div class="box">
            <p>토탈 이상</p>
            <template v-if="configData != null">
              <p v-if="configData.v66 === '0'" class="state blue">정상</p>
              <p v-else class="state red">이상</p>
            </template>
            <template v-else><p>-</p></template>
          </div>
        </div>
        <div class="container">
          <div class="box">
            <p style="font-size: 14px">압축기4 토탈 이상</p>
            <template v-if="configData != null">
              <p v-if="configData.v98 === '0'" class="state blue">정상</p>
              <p v-else class="state red">이상</p>
            </template>
            <template v-else><p>-</p></template>
          </div>
          <div class="box">
            <p>온도센서 이상</p>
            <template v-if="configData != null">
              <p v-if="configData.v99 === '0'" class="state blue">정상</p>
              <p v-else class="state red">이상</p>
            </template>
            <template v-else><p>-</p></template>
          </div>
        </div>
        <div class="container">
          <div class="box">
            <p>습도센서 이상</p>
            <template v-if="configData != null">
              <p v-if="configData.v100 === '0'" class="state blue">정상</p>
              <p v-else class="state red">이상</p>
            </template>
            <template v-else><p>-</p></template>
          </div>
          <div class="box">
            <p>통신센서 이상</p>
            <template v-if="configData != null">
              <p v-if="configData.v101 === '0'" class="state blue">정상</p>
              <p v-else class="state red">이상</p>
            </template>
            <template v-else><p>-</p></template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="info-wrap">
    <p class="title" style="font-size: 18px; font-weight: 700; color: #84c8c0; padding-left: 10px">실시간 트랜드</p>
    <hr style="width: 100%; height: 1px; margin: 10px 0; border: 0; background: #343b4f" />
    <AreaChart ref="chartRef" :item="null" />
  </div>
  <DeviceSettingModal v-if="isModalViewed && configData != null" :device-i-d="configData.deviceID" :modal-data-type="modalDataType" @close-modal="isModalViewed = false" @change-success="changeData" />
</template>
<script>
import AreaChart from '@/components/pc/dashboard/chart/AreaChart.vue'
import DeviceSettingModal from '@/components/pc/dashboard/config/modal/DeviceSettingModal.vue'

export default {
  name: 'DetailType100101',
  components: { DeviceSettingModal, AreaChart },
  props: {
    configData: {
      type: Object,
      required: false,
      default: null,
    },
    chartData: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      editable: true,
      isModalViewed: false,
      modalDataType: 0,
    }
  },
  watch: {
    chartData() {
      this.$refs.chartRef.dataChange(this.chartData)
    },
  },
  methods: {
    changeData() {},
    showSettingModal(type) {
      if (this.editable) {
        this.modalDataType = type
        this.isModalViewed = true
      }
    },
  },
}
</script>
