<template>
  <div>
    <div class="server-info-wrap" style="margin-right: 30px; float: left">
      <p class="title">CPU</p>
      <hr />
      <table>
        <tr>
          <td style="width: 200px">코어 수</td>
          <td v-if="serverInfo">4</td>
        </tr>
        <tr>
          <td>사용량</td>
          <td v-if="serverInfo">6.3 %</td>
        </tr>
        <tr>
          <td>시스템 사용률</td>
          <td v-if="serverInfo">10.5 %</td>
        </tr>
        <tr>
          <td>현재 공실률</td>
          <td v-if="serverInfo">88.1 %</td>
        </tr>
      </table>
    </div>
    <div class="server-info-wrap">
      <p class="title">RAM</p>
      <hr />
      <table>
        <tr>
          <td style="width: 200px">총메모리</td>
          <td v-if="serverInfo">{{ serverInfo.totalRam }}</td>
        </tr>
        <tr>
          <td>사용량</td>
          <td v-if="serverInfo">{{ serverInfo.usedRam }}</td>
        </tr>
        <tr>
          <td>남은 메모리</td>
          <td v-if="serverInfo">{{ serverInfo.emptyRam }}</td>
        </tr>
        <tr>
          <td>사용률</td>
          <td v-if="serverInfo">{{ serverInfo.usedRamPer }}</td>
        </tr>
      </table>
    </div>
    <div class="server-info-wrap" style="width: 100%; margin-top: 30px;">
      <p class="title">Server Info.</p>
      <hr />
      - F/W Version: 1.0 <br>
      - 모델명: 1.0 <br>
      - 위치: 승일일렉트로닉스 (경기 부천시 원미구 부천로208번길 54) <br>
      - 설치 날짜: 2024.08.28 <br>
      - 데이터 저장 주기장치 소유자: EIP <br>
      - 최대 접근 가능자수 100<br>
      - 현재 접속자수 3<br>
    </div>
  </div>
</template>
<script>
import commonMixin from '@/mixin/commonMixin'
import { memberStore } from '@/store/member.store'
import { commonStore } from '@/store/common.store'

export default {
  mixins: [commonMixin],
  setup() {
    const useStoreMember = memberStore()
    const useStoreCommon = commonStore()
    return { useStoreMember, useStoreCommon }
  },
  data() {
    return {
      serverInfo: null,
    }
  },
  mounted() {
    this.getServerInfo()
  },
  methods: {
    getServerInfo() {
      this.useStoreCommon.reqGetServerInfo().then((r) => {
        if (r) this.serverInfo = this.useStoreCommon.getServerInfo
      })
    },
  },
}
</script>
