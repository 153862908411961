import { memberStore } from '@/store/member.store'
import DashBoardMain from '@/components/mobile/dashboard/DashBoardMain.vue'
import DashBoardDetail from '@/components/mobile/dashboard/DashBoardDetail.vue'
import MMainView from '@/views/mobile/Main.vue'

const onlyAuth = (to, from, next) => {
  const userStore = memberStore()
  if (userStore.isAuthenticated) next()
  else next('/mobile/login')
}
export default [
  {
    path: '/mobile/dash',
    redirect: '/mobile/dash/main',
    components: {
      app_router: MMainView,
    },
    meta: {
      title: 'DashBoard',
    },
    children: [
      {
        path: 'main',
        beforeEnter: onlyAuth,
        components: {
          main_router: DashBoardMain,
        },
      },
      {
        path: 'detail',
        beforeEnter: onlyAuth,
        components: {
          main_router: DashBoardDetail,
        },
      },
    ],
  },
]
