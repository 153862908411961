<template>
  <div class="modal">
    <div class="overlay" @click="$emit('close-modal')" />
    <div class="modal-content modal2">
      <form>
        <p class="title">모니터링 설정</p>
        <img class="exit-btn" alt="이전 페이지" src="" @click="closeModal" />

        <div class="modal_left_wrap">
          <p class="sub_title">알림 메세지</p>
          <div class="content">
            <table>
              <colgroup>
                <col style="width: 60px" />
                <col style="width: 40px" />
                <col />
                <col style="width: 60px" />
                <col style="width: 60px" />
              </colgroup>
              <tr>
                <th>적용</th>
                <th>No</th>
                <th>내용</th>
                <th>최소</th>
                <th>최대</th>
              </tr>
              <tr v-for="(item, index) in alarmList" :key="index" class="item" @click="checkItem(index)">
                <td><input v-model="item.active" type="checkbox" /></td>
                <td>{{ index + 1 }}</td>
                <td>{{ item.protocolName }}</td>
                <td>{{ item.minValue }}</td>
                <td>{{ item.maxValue }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="modal_right_wrap">
          <p class="sub_title">알림 유저</p>
          <div class="top_menu">
            <select v-model="selectedMemberID">
              <option value="">유저 목록</option>
              <option v-for="(item, index) in memberList" :key="index" :value="item.id">{{ item.fullName }} ( {{ item.loginName }} )</option>
            </select>
            <button class="blue default_btn" @click="addMember">
              <span>추가</span>
            </button>
          </div>
          <div class="content">
            <table>
              <colgroup>
                <col style="width: 40px" />
                <col />
                <col style="width: 60px" />
              </colgroup>
              <tr>
                <th>No</th>
                <th>담당자</th>
                <th>삭제</th>
              </tr>
              <tr v-for="(item, index) in selectedMemberList" :key="index" class="item">
                <td>{{ index + 1 }}</td>
                <td>{{ item.fullName }}</td>
                <td>
                  <button class="red default_btn table_btn" @click="removeMember(item.id, $event)">
                    <span>삭제</span>
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <button class="blue default_btn" @click="saveMonitoring">
          <span>저장</span>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import commonMixin from '@/mixin/commonMixin';
import { deviceStore } from '@/store/device.store';
import { memberStore } from '@/store/member.store';

export default defineComponent({
  mixins: [commonMixin],
  props: {
    selectedItem: {
      type: Object,
      default: null,
    },
    deviceTypeList: {
      type: Array,
      default: null,
    },
  },
  emits: ['close-modal', 'change-success'],
  setup(props) {
    const computedValue = computed(() => {
      return props.selectedItem;
    });

    const useStoreMember = memberStore();
    const useStoreDevice = deviceStore();
    return { useStoreMember, useStoreDevice, computedValue };
  },
  data() {
    return {
      id: '',
      memberList: [],
      alarmList: [],
      selectedMemberList: [],
      selectedMemberID: '',
    };
  },
  mounted() {
    if (this.computedValue) {
      this.id = this.computedValue.id;
    }
    this.getMemberListAll();
  },
  methods: {
    getMemberListAll() {
      this.useStoreMember.reqMemberListAll().then((memberList) => {
        this.memberList = memberList;
        this.getDeviceAlarmList();
      });
    },
    getDeviceAlarmList() {
      const deviceData = { id: this.id };
      this.useStoreDevice.reqGetAlarmList(deviceData).then((r) => {
        if (r) {
          this.alarmList = this.useStoreDevice.getAlarmList;
          const tempMemberList = this.useStoreDevice.getAlarmMemberList;
          for (let index in tempMemberList) {
            this.selectedMemberID = Number(tempMemberList[index]);
            this.addMember();
          }
        }
      });
    },
    closeModal(e) {
      e.preventDefault();
      this.$emit('close-modal');
    },
    checkItem(no) {
      this.alarmList[no].active = !this.alarmList[no].active;
    },
    addMember(e) {
      if (e != null) e.preventDefault();
      if (this.selectedMemberID === '') {
        return;
      }
      const memberItemIndex = this.memberList.findIndex((item) => item.id === this.selectedMemberID);
      if (memberItemIndex !== -1) {
        const removedMember = this.memberList.splice(memberItemIndex, 1)[0];
        this.selectedMemberList.push(removedMember);
        this.selectedMemberID = '';
      }
    },
    removeMember(index, e) {
      e.preventDefault();
      const selectedMemberID = index;
      const memberItemIndex = this.selectedMemberList.findIndex((item) => item.id === selectedMemberID);
      if (memberItemIndex !== -1) {
        const removedMember = this.selectedMemberList.splice(memberItemIndex, 1)[0];
        this.memberList.push(removedMember);
      }
    },
    saveMonitoring(e) {
      e.preventDefault();
      const activeItemIds = this.alarmList.filter((item) => item.active === true).map((item) => item.protocolId);
      const activeMemberIds = this.selectedMemberList.map((item) => item.id);

      const alarmData = {
        deviceId: this.id,
        memberList: activeMemberIds,
        protocolList: activeItemIds,
      };

      this.useStoreDevice.reqAddDeviceAlarm(alarmData).then((r) => {
        if (r) {
          alert('모니터링이 설정 되었습니다.');
          this.$emit('close-modal');
        }
      });
    },
  },
});
</script>
