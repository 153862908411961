import { memberStore } from '@/store/member.store';
import MainView from '@/views/pc/Main.vue';
import HistoryMain from "@/components/pc/history/HistoryMain.vue";

const onlyAuth = (to, from, next) => {
  const userStore = memberStore();
  if (userStore.isAuthenticated) next();
  else next('/login');
};
export default [
  {
    path: '/history',
    redirect: '/history/main',
    components: {
      app_router: MainView,
    },
    meta: {
      title: '이력 관리',
    },
    children: [
      {
        path: 'main',
        name: 'HistoryMain',
        beforeEnter: onlyAuth,
        components: {
          main_router: HistoryMain,
        },
      },
    ],
  },
];
