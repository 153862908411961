const getAccessToken = () => {
  if (localStorage.getItem('accessToken') == null || localStorage.getItem('refreshToken') == null) return null
  else
    return {
      accessToken: localStorage.getItem('accessToken'),
      refreshToken: localStorage.getItem('refreshToken'),
    }
}

const setAccessToken = (jwt) => {
  localStorage.setItem('accessToken', jwt.accessToken)
  localStorage.setItem('refreshToken', jwt.refreshToken)
}

const setMemberInfoStorage = (member) => {
  localStorage.setItem('memberNickName', member.memberNickName)
  localStorage.setItem('memberAuth', member.memberAuth)
}

const getMemberInfoStorage = () => {
  if (localStorage.getItem('memberNickName') == null || localStorage.getItem('memberAuth') == null) return null
  else
    return {
      memberNickName: localStorage.getItem('memberNickName'),
      memberAuth: localStorage.getItem('memberAuth'),
    }
}

const deleteAllMemberInfo = () => {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('memberNickName')
  localStorage.removeItem('memberAuth')
}

export default {
  getAccessToken,
  setAccessToken,
  setMemberInfoStorage,
  getMemberInfoStorage,
  deleteAllMemberInfo,
}
