import App from './App.vue'
import { router } from './router'
import { createApp } from 'vue'
import jQuery from '@/assets/js/jquery-3.4.1.min.js'
import { createPinia } from 'pinia'
import VueDatePicker from '@vuepic/vue-datepicker'
import VueApexCharts from 'vue3-apexcharts'
import '@vuepic/vue-datepicker/dist/main.css'

const app = createApp(App)
app.use(router)
app.use(createPinia())
app.use(VueApexCharts)
app.mount('#app')
app.component('VueDatePicker', VueDatePicker)

global.$ = jQuery
