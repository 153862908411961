<template>
  <div class="main_wrap">
    <div class="left-div">
      <div class="menu-item">
        <p :class="{ on: selectedMenu === 0 }" @click="changeMenu(0)">경보 이력</p>
        <p :class="{ on: selectedMenu === 1 }" @click="changeMenu(1)">사용자 설정 이력</p>
        <p :class="{ on: selectedMenu === 2 }" @click="changeMenu(2)">Device 설정 이력</p>
        <p :class="{ on: selectedMenu === 3 }" @click="changeMenu(3)">전체 trend 이력</p>
        <p :class="{ on: selectedMenu === 4 }" @click="changeMenu(4)">보고서</p>
      </div>
    </div>
    <div class="right-div">
      <div class="top-div">
        <p class="title"># 이력 정보 - {{ title }}</p>
      </div>
      <div class="bottom-div">
        <AlarmHistory v-if="selectedMenu === 0" />
        <UserSettingHistory v-if="selectedMenu === 1" />
        <DeviceSettingHistory v-if="selectedMenu === 2" />
        <TrendHistory v-if="selectedMenu === 3" />
        <ReportHistory v-if="selectedMenu === 4" />
      </div>
    </div>
  </div>
</template>
<script>
import commonMixin from '@/mixin/commonMixin'
import AlarmHistory from '@/components/pc/history/alarm/AlarmHistory.vue'
import UserSettingHistory from '@/components/pc/history/userSetting/UserSettingHistory.vue'
import DeviceSettingHistory from '@/components/pc/history/deviceSetting/DeviceSettingHistory.vue'
import TrendHistory from '@/components/pc/history/trend/TrendHistory.vue'
import ReportHistory from '@/components/pc/history/report/ReportHistory.vue'

export default {
  components: { ReportHistory, TrendHistory, DeviceSettingHistory, UserSettingHistory, AlarmHistory },
  mixins: [commonMixin],
  setup() {},
  data() {
    return {
      selectedMenu: 0,
      title: '',
    }
  },
  mounted() {
    this.changeMenu(0)
  },
  methods: {
    changeMenu(num) {
      this.selectedMenu = num
      if (num === 0) this.title = '경보 이력'
      else if (num === 1) this.title = '사용자 설정 이력'
      else if (num === 2) this.title = 'Device 설정 이력'
      else if (num === 3) this.title = '전체 trend 이력'
      else if (num === 4) this.title = '보고서'
    },
  },
}
</script>
