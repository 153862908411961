import axios from 'axios'
import { memberStore } from '@/store/member.store'

const instance = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}`,
  timeout: 54000,
})

instance.interceptors.request.use(
  (config) => {
    if (config.url === '/member/join' || config.url === '/member/login' || config.url === '/getMacAddress') {
      return config
    }
    const useStoreMember = memberStore()
    if (config.url === '/member/logout' || config.url === '/member/reissueToken') {
      const accessToken = useStoreMember.getRefreshToken
      config.headers['Content-Type'] = 'application/json'
      if (config.url === '/member/reissueToken') config.headers['Authorization'] = `Bearer ${accessToken}`
      else config.headers['AuthorizationJWT'] = `Bearer ${accessToken}`
    } else {
      const accessToken = useStoreMember.getAccessToken
      config.headers['Content-Type'] = 'application/json'
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

instance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    error.handled = true
    const originalRequest = error.config
    if (error.response && error.response.status === 401) {
      const useStoreMember = memberStore()
      if (error.config.url !== '/member/reissueToken') {
        await useStoreMember.reissueToken()
        const newAccessToken = useStoreMember.getAccessToken

        originalRequest.headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${newAccessToken}`,
        }
        return await axios.request(originalRequest).catch(function () {
          useStoreMember.logout()
        })
      }
    }
    return null
  },
)

export default instance
