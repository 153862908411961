<template>
  <div class="right-div">
    <div class="bottom-div">
      <div class="btn-div">
        <button class="function_btn" :class="{ on: dataShowOption === 'Config' }" @click="showConfig">
          <span>감시화면</span>
        </button>
        <button class="function_btn" :class="{ on: dataShowOption === 'Trend' }" @click="showTrend">
          <span>트랜드</span>
        </button>
        <button class="function_btn" :class="{ on: dataShowOption === 'History' }" @click="showHistory">
          <span>경보이력</span>
        </button>
      </div>
      <ConfigDevice v-if="dataShowOption === 'Config'" :item-i-d="selectedDashItemID" :device-type="selectedItemModel" />
      <TrendData v-if="dataShowOption === 'Trend'" :item-i-d="selectedDashItemID" :device-type="selectedItemModel" />
      <HistoryData v-if="dataShowOption === 'History'" :item-i-d="selectedDashItemID" :device-type="selectedItemModel" />
    </div>
  </div>
</template>

<script>
import { dashStore } from '@/store/dash.store'
import commonMixin from '@/mixin/commonMixin'
import { commonStore } from '@/store/common.store'
import ConfigDevice from '@/components/mobile/dashboard/config/ConfigData.vue'
import TrendData from '@/components/mobile/dashboard/trend/TrendData.vue'
import HistoryData from '@/components/mobile/dashboard/history/HistoryData.vue'

export default {
  name: 'DashMain',
  components: { HistoryData, TrendData, ConfigDevice },
  mixins: [commonMixin],
  setup() {
    const useStoreDash = dashStore()
    const useCommonStore = commonStore()
    useCommonStore.setMenuTitle('Dash Board')

    return { useStoreDash, useCommonStore }
  },
  data() {
    return {
      selectedDashItemID: null,
      selectedItemModel: null,
      dashBoardItemList: [],
      dashBoardConfigData: null,

      dashBoardChartItemList: [],

      searchStartDate: '',
      searchEndDate: '',

      connectFlag: false,
      alarmFlag: false,
      dataShowOption: 'Config',
    }
  },
  mounted() {
    this.selectedDashItemID = this.$route.query.id
    this.selectedItemModel = this.$route.query.deviceModel
  },
  methods: {
    showConfig() {
      this.dataShowOption = 'Config'
    },
    showTrend() {
      this.dataShowOption = 'Trend'
    },
    showHistory() {
      this.dataShowOption = 'History'
    },
  },
}
</script>
