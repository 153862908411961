<template>
  <div class="table-wrap">
    <table>
      <colgroup>
        <col v-for="item in itemOption" :key="item.name" :style="'width:' + item.width" />
      </colgroup>
      <tr>
        <th v-for="item in itemOption" :key="item.name">{{ item.name }}</th>
      </tr>
      <tr v-for="(item, index) in itemList" :key="item.id" :class="{ red: item.isRed }" @click="handleRowClick(index)">
        <td v-for="option in itemOption" :key="option.name">
          <span v-if="option.target === 'no'">{{ pageInfo.totalCnt - index - 10 * pageInfo.pageIndex }}</span>
          <span v-else-if="option.isStatus" :class="item[option.target] ? 'activate' : 'disabled'">
            <template v-if="item[option.target]">{{ option.trueValue }}</template>
            <template v-else>{{ option.falseValue }}</template>
          </span>
          <span v-else>{{ item[option.target] || '-' }}</span>
        </td>
      </tr>

      <tr v-if="itemList.length === 0">
        <td :colspan="itemOption.length">데이터가 없습니다.</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    itemList: {
      type: Array,
      require: true,
      default: null,
    },
    itemOption: {
      type: Object,
      require: true,
      default: null,
    },
    pageInfo: {
      type: Object,
      required: false,
      default: null,
    },
  },
  emits: ['row-clicked'],
  methods: {
    handleRowClick(rowIndex) {
      this.$emit('row-clicked', rowIndex)
    },
  },
}
</script>
