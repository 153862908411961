<template>
  <div class="main_wrap">
    <div class="left-div">
      <div class="device_list_wrap">
        <div v-for="(item, index) in dashBoardItemList" :key="index" class="dash_item" :class="{ on: item.deviceID === selectedDashItemID }" @click="clickDeviceItem(index)">
          <div class="isError" :class="{ on: item.isError > 0 && isErrorActive[index] }"></div>
          <p class="title">
            {{ item.name }}
            <span style="display: flex; align-items: center">
              <template v-if="item.deviceModel === '100101'">
                <span v-if="item.viewData3 === '1'" class="state on">운행</span>
                <span v-else class="state">정지</span>
              </template>

              <img v-if="item.linkStatus" class="link_state" :src="require('@/assets/images/ico_link_success.png')" alt="" />
              <img v-else class="link_state" :src="require('@/assets/images/ico_link_fail.png')" alt="" />
            </span>
          </p>
          <template v-if="item.deviceModel === '100101'">
            <div class="view-data-box">
              <span style="color: #e3d294">{{ item.viewData1 / 10 }}</span> ℃ <span style="margin-left: 30px; color: #84c8c0">{{ item.viewData2 }}</span> %
            </div>
            <div class="state_box">
              <span class="state" :class="{ on: item.viewData4 === '1' }">팬</span>
              <span class="state" :class="{ on: item.viewData5 === '1' }">냉방</span>
              <span class="state" :class="{ on: item.viewData6 === '1' }">난방</span>
              <span class="state" :class="{ on: item.viewData7 === '1' }">가습</span>
              <span class="state" :class="{ on: item.viewData8 === '1' }">제습</span>
            </div>
          </template>
          <template v-if="item.deviceModel === '100201'">
            <div class="view-data-box">
              <span style="color: #e3d294">{{ item.viewData1 / 10 }}</span> ℃ <span style="margin-left: 30px; color: #84c8c0">{{ item.viewData2 }}</span> %
            </div>
          </template>
          <template v-if="item.deviceModel === '100301'">
            <div class="view-data-box" style="font-size: 22px; margin-bottom: 0">
              <span style="color: #e3d294;"> 룸온도 : {{ item.viewData1 / 10 }} </span> ℃ <span style="margin-left: 30px; color: #e3d294"> 과열도 : {{ item.viewData2 }} </span> %
            </div>
            <div class="view-data-box" style="font-size: 22px; margin-bottom: 0">
              <span style="color: #84c8c0">고압 : {{ item.viewData3 / 10 }}</span> bar <span style="margin-left: 30px; color: #84c8c0">저압 : {{ item.viewData4 }}</span> bar
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dashStore } from '@/store/dash.store';
import commonMixin from '@/mixin/commonMixin';
import { commonStore } from '@/store/common.store';
import { router } from '@/router';

export default {
  name: 'DashMain',
  mixins: [commonMixin],
  setup() {
    const useStoreDash = dashStore();
    const useCommonStore = commonStore();
    useCommonStore.setMenuTitle('Dash Board');

    return { useStoreDash, useCommonStore };
  },
  data() {
    return {
      title: '',
      selectedDashItemID: 0,
      selectedItemModel: '',

      dashBoardItemList: null,
      connectFlag: false,
      alarmFlag: false,
      dataShowOption: 'Config',

      isErrorActive: {},
    }
  },
  beforeCreate() {
    this.startTime = new Date();

    const year = this.startTime.getFullYear();
    const month = ('0' + (this.startTime.getMonth() + 1)).slice(-2);
    const day = ('0' + this.startTime.getDate()).slice(-2);

    const hours = this.startTime.getHours();
    const minutes = this.startTime.getMinutes();
    const seconds = this.startTime.getSeconds();
    const milliseconds = this.startTime.getMilliseconds();

    console.log(`before create : ${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`);
  },
  mounted() {
    this.getDashBoardData()
    this.dataInterval = setInterval(() => {
      this.getDashBoardData()
    }, 7000)
  },
  beforeUnmount() {
    clearInterval(this.errorToggleInterval)
    clearInterval(this.dataInterval)
  },
  methods: {
    startErrorToggle(index) {
      this.isErrorActive = {
        ...this.isErrorActive,
        [index]: true,
      }
      this.errorToggleInterval = setInterval(() => {
        this.isErrorActive = {
          ...this.isErrorActive,
          [index]: !this.isErrorActive[index],
        }
      }, 500)
    },
    stopErrorToggle(index) {
      if (this.errorToggleInterval) {
        clearInterval(this.errorToggleInterval)
        this.errorToggleInterval = null
      }
      this.isErrorActive = {
        ...this.isErrorActive,
        [index]: false,
      }
    },
    getDashBoardData() {
      this.useStoreDash.reqDashBoardDeviceList().then((result) => {
        if (result) {
          this.dashBoardItemList = this.useStoreDash.getDashBoardItemList
          if (this.selectedDashItemID === 0) {
            this.selectedDashItemID = this.dashBoardItemList[0].deviceID
            this.title = this.dashBoardItemList[0].name
            this.selectedItemModel = this.dashBoardItemList[0].deviceModel
          }

          this.dashBoardItemList.forEach((item, index) => {
            this.stopErrorToggle(index)
          })

          this.dashBoardItemList.forEach((item, index) => {
            if (item.isError > 0) this.startErrorToggle(index)
          })
        }
        this.useCommonStore.stopLoading()
      })
    },
    clickDeviceItem(index) {
      router.push(`/mobile/dash/detail?id=${this.dashBoardItemList[index].deviceID}&deviceModel=${this.dashBoardItemList[index].deviceModel}`);
    },
    sortData() {},
    filterData() {},
  },
};
</script>
