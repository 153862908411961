import { memberStore } from '@/store/member.store'
import DashBoardMain from '@/components/pc/dashboard/DashBoardMain.vue'
import MainView from '@/views/pc/Main.vue'

const onlyAuth = (to, from, next) => {
  const userStore = memberStore()
  if (userStore.isAuthenticated) next()
  else next('/login')
}
export default [
  {
    path: '/dash',
    redirect: '/dash/main',
    components: {
      app_router: MainView,
    },
    meta: {
      title: 'DashBoard',
    },
    children: [
      {
        path: 'main',
        beforeEnter: onlyAuth,
        components: {
          main_router: DashBoardMain,
        },
      },
    ],
  },
]
