<template>
  <div class="modal">
    <div class="overlay" @click="$emit('close-modal')" />
    <div class="modal-content" style="width: 800px; margin: 250px auto auto">
      <form>
        <p class="title">장비 권한 설정</p>
        <div v-if="!formMod">
          <table>
            <colgroup></colgroup>
            <tr>
              <th>no</th>
              <th>장비명</th>
              <th>장비 종류</th>
              <th>장비 모델</th>
              <th>권한</th>
              <th>삭제</th>
            </tr>
            <tr v-for="(item, index) in deviceAuthList" :key="item.id">
              <td>
                <span>{{ index + 1 }}</span>
              </td>
              <td>
                <span>{{ item.name }}</span>
              </td>
              <td>
                <span>{{ item.deviceTypeName }}</span>
              </td>
              <td>
                <span>{{ item.deviceModelName }}</span>
              </td>
              <td>
                <span v-if="item.deviceAuth === '1'">read</span>
                <span v-else-if="item.deviceAuth === '2'">read/write</span>
              </td>
              <td>
                <button class="red default_btn" style="height: 30px" @click="deleteDeviceAuth(item.id, $event)">
                  <span>삭제</span>
                </button>
              </td>
            </tr>

            <tr v-if="deviceAuthList.length === 0">
              <td colspan="8">데이터가 없습니다.</td>
            </tr>
          </table>
          <div class="button-wrap" style="margin-top: 20px">
            <button class="blue default_btn" style="height: 40px; margin-right: 20px" @click="addMod">
              <span>+ 권한 장비 추가</span>
            </button>
            <button class="grey default_btn" style="height: 40px" @click="$emit('close-modal')">
              <span>닫기</span>
            </button>
          </div>
        </div>
        <div v-else>
          <label style="margin-right: 30px; width: 80px; display: inline-block">장비명</label>
          <select v-model="selectedDevice" style="width: 250px">
            <option value="">장비명</option>
            <option v-for="(item, index) in deviceList" :key="index" :value="item.id">{{ item.name }}</option>
          </select>
          <br />
          <label style="margin-right: 30px; width: 80px; display: inline-block">장비 종류</label>
          <input v-model="selectedTypeName" style="width: 250px" type="text" placeholder="장비를 선택 해 주세요." autocomplete="off" maxlength="16" readonly />
          <br />
          <label style="margin-right: 30px; width: 80px; display: inline-block">장비 모델</label>
          <input v-model="selectedModelName" style="width: 250px" type="text" placeholder="장비를 선택 해 주세요." autocomplete="off" maxlength="16" readonly />
          <br />
          <label style="margin-right: 30px; width: 80px; display: inline-block">권한</label>
          <select v-model="auth" style="width: 250px">
            <option value="">권한</option>
            <option value="1">read</option>
            <option value="2">read/write</option>
          </select>
          <div class="button-wrap" style="margin-top: 20px">
            <button class="blue default_btn" style="height: 40px; margin-right: 10px" @click="addAuth">
              <span>저장</span>
            </button>
            <button class="grey default_btn" style="height: 40px" @click="listMod">
              <span>뒤로</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { memberStore } from '@/store/member.store'
import { deviceStore } from '@/store/device.store'
import commonMixin from '@/mixin/commonMixin'

export default defineComponent({
  mixins: [commonMixin],
  props: {
    selectedItem: {
      type: Object,
      default: null,
    },
  },
  emits: ['close-modal', 'change-success'],
  setup(props) {
    const computedValue = computed(() => {
      return props.selectedItem
    })

    const useStoreMember = memberStore()
    const useStoreDevice = deviceStore()
    return { useStoreMember, computedValue, useStoreDevice }
  },
  data() {
    return {
      deviceAuthList: [],
      deviceList: [],
      formMod: false,

      auth: '',
      selectedDevice: '',
      selectedTypeName: '',
      selectedModelName: '',
    }
  },
  watch: {
    selectedDevice() {
      if (this.selectedDevice !== '') {
        const item = this.deviceList.find((item) => item.id === this.selectedDevice)
        this.selectedTypeName = item.deviceTypeName
        this.selectedModelName = item.deviceModelName
      } else {
        this.selectedTypeName = ''
        this.selectedModelName = ''
      }
    },
  },
  mounted() {
    this.getAuthList()
  },
  methods: {
    addMod(e) {
      e.preventDefault()
      this.formMod = true
    },
    listMod(e) {
      e.preventDefault()
      this.formMod = false
    },
    deleteDeviceAuth(id, e) {
      e.preventDefault()
      if (confirm('해당 장비 권한을 삭제 하시겠습니까?')) {
        this.useStoreDevice.reqDeleteDeviceAuth(id).then((r) => {
          if (r) {
            alert('삭제 되었습니다.')
            this.getAuthList()
          }
        })
      }
    },
    getAuthList() {
      this.useStoreDevice.reqDeviceAuthList(this.selectedItem.id).then((r) => {
        if (r) {
          this.deviceAuthList = this.useStoreDevice.getDeviceAuthList
          this.getDeviceList()
        }
      })
    },
    addAuth(e) {
      e.preventDefault()
      if (this.selectedDevice === '') {
        alert('장비를 선택 해 주세요.')
        return
      }
      if (this.auth === '') {
        alert('권한을 선택 해 주세요.')
        return
      }

      const authData = {
        deviceID: this.selectedDevice,
        deviceAuth: this.auth,
        memberID: this.selectedItem.id,
      }

      this.useStoreDevice.addDeviceAuth(authData).then((r) => {
        if (r) {
          alert('장비 권한이 추가 되었습니다.')
          this.auth = ''
          this.selectedDevice = ''
          this.selectedTypeName = ''
          this.selectedModelName = ''

          this.getAuthList()
          this.formMod = false
        }
      })
    },
    modifyAuth() {},
    closeModal(e) {
      e.preventDefault()
      this.$emit('close-modal')
    },
    getDeviceList() {
      this.useStoreDevice.reqDeviceList().then((r) => {
        if (r) this.deviceList = this.useStoreDevice.getDeviceList
      })
    },
  },
})
</script>
