import { memberStore } from '@/store/member.store';
import mLoginView from '@/views/mobile/member/Login.vue';
import mSignView from '@/views/mobile/member/SignUp.vue'

const onlyNotAuth = (to, from, next) => {
  const userStore = memberStore();
  if (!userStore.isAuthenticated) next();
  else next('/mobile');
};

export default [
  {
    path: '/mobile/login',
    components: {
      app_router: mLoginView,
      app_mobile_router: mLoginView,
    },
    beforeEnter: onlyNotAuth,
    meta: {
      title: '로그인',
    },
  },
  {
    path: '/mobile/signUp',
    components: {
      app_router: mSignView,
      app_mobile_router: mSignView,
    },
    beforeEnter: onlyNotAuth,
    meta: {
      title: '회원가입',
    },
  },
];
