<template>
  <div class="info-wrap">
    <div class="bottom_wrap" style="min-height: 700px;">
      <button class="blue default_btn add_btn" @click="exportExcel">
        <span>엑셀 출력</span>
      </button>
      <CommonTable :item-option="itemOption" :item-list="historyList" :page-info="pageInfo" @row-clicked="handleRowClick" />
      <CommonPaging v-if="historyList.length !== 0" :page-info="pageInfo" @move-page="handlePage" />
    </div>
  </div>
</template>
<script>
import commonMixin from '@/mixin/commonMixin'
import { dashStore } from '@/store/dash.store'
import { commonStore } from '@/store/common.store'
import CommonPaging from '@/components/pc/common/CommonPaging.vue'
import CommonTable from '@/components/pc/common/CommonTable.vue'
import {deviceStore} from "@/store/device.store";

export default {
  components: { CommonTable, CommonPaging },
  mixins: [commonMixin],
  props: {
    itemID: {
      type: [Number, String],
      required: true,
    },
    deviceType: {
      type: [Number, String],
      required: true,
    },
  },
  setup() {
    const useStoreDash = dashStore()
    const useCommonStore = commonStore()
    const useStoreDevice = deviceStore()

    return { useStoreDash, useCommonStore, useStoreDevice }
  },
  data() {
    return {
      page: 1,
      pageInfo: [],
      historyList: [],
      itemOption: [
        { name: 'NO', width: '4%', target: 'no' },
        { name: '경보 내역', width: '20%', target: 'name' },
        { name: '발생 시간', width: '10%', target: 'createdDate' },
        { name: '해제 시간', width: '10%', target: 'releaseDate' },
      ],
    }
  },
  mounted() {
    this.getAlertHistoryList()
  },
  methods: {
    getAlertHistoryList() {
      this.useStoreDevice.reqAlertHistoryPaging(this.page, this.itemID).then((r) => {
        if (r) {
          this.historyList = this.useStoreDevice.getAlertHistoryList
          this.pageInfo = this.useStoreDevice.getPageInfo
        }
      })
    },
    handleRowClick(rowIndex) {
      console.log(rowIndex)
    },
    exportExcel(e) {
      e.preventDefault()
    },
  },
}
</script>
