<template>
  <div class="fix-menu">
    <img class="menu-icon" :src="require('@/assets/images/ico_menu.png')" alt="menu" @click="moveMenu('/')" />
    <!--    <img class="menu-icon" :src="require('@/assets/images/ico_speaker.png')" alt="menu" @click="moveMenu('/')" />-->
    <img class="menu-icon" :src="require('@/assets/images/ico_history.png')" alt="menu" @click="moveMenu('/history')" />
    <img class="menu-icon" :src="require('@/assets/images/ico_setting.png')" alt="menu" @click="moveMenu('/setting')" />
    <img class="menu-icon" :src="require('@/assets/images/ico_logout.png')" alt="menu" @click="logout" />
  </div>

  <!--  <div class="left-menu" :class="{ on: isMenuOpen }">-->
  <!--    <div class="top">-->
  <!--      &lt;!&ndash;<img class="menu-logo" :src="require('@/assets/images/ico_menu_logo.png')" alt="menu">&ndash;&gt;-->
  <!--      <img class="menu-icon" :src="require('@/assets/images/ico_menu.png')" alt="menu" @click="changeMenuOpen" />-->
  <!--    </div>-->
  <!--    <ul class="nav-list">-->
  <!--      <li v-for="(item, index) in items" :key="index">-->
  <!--        <MenuItem :item="item" :menu-flag="menuFlag" @change-flag="changeMenuFlag" />-->
  <!--      </li>-->
  <!--    </ul>-->

  <!--    <div class="bottom">-->
  <!--      <img class="menu-icon" :src="require('@/assets/images/ico_logout.png')" alt="menu" @click="logout" />-->
  <!--    </div>-->
  <!--  </div>-->
</template>

<script>
// import MenuItem from './MenuItem.vue';
import { commonStore } from '@/store/common.store'
import { memberStore } from '@/store/member.store'
import { computed } from 'vue'
import { router } from '@/router'

export default {
  components: {
    // MenuItem,
  },
  setup() {
    const computedValue = computed(() => {
      return this.isMenuOpen
    })
    const useCommonStore = commonStore()
    const useStoreMember = memberStore()
    return { useCommonStore, computedValue, useStoreMember }
  },
  data() {
    return {
      items: [
        { label: 'Dash Board', icon: require('@/assets/images/ico_dash.png'), url: '/dash/main' },
        {
          label: 'History',
          icon: require('@/assets/images/ico_history.png'),
          url: '/history',
          child: [
            { label: '경보 이력', url: '/history/alarm' },
            { label: '장비 서비스 이력', url: '/history/device' },
          ],
        },
        {
          label: 'Setting',
          icon: require('@/assets/images/ico_setting.png'),
          url: '/setting',
          child: [
            { label: '사용자 관리', url: '/setting/user' },
            { label: '장비 관리', url: '/setting/device' },
            { label: '통합 설정', url: '/setting/commonSetting' },
          ],
        },
      ],
      menuFlag: '',
      isMenuOpen: false,
      auth: this.useStoreMember.getMemberInfo.auth,
    }
  },
  created() {
    if (this.auth === 'ROLE_MASTER') {
      this.items = [
        { label: 'Dash Board', icon: require('@/assets/images/ico_dash.png'), url: '/dash/main' },
        {
          label: 'History',
          icon: require('@/assets/images/ico_history.png'),
          url: '/history',
          child: [
            { label: '경보 이력', url: '/history/alarm' },
            { label: '장비 서비스 이력', url: '/history/device' },
          ],
        },
        {
          label: 'Setting',
          icon: require('@/assets/images/ico_setting.png'),
          url: '/setting',
          child: [
            { label: '사용자 관리', url: '/setting/user' },
            { label: '장비 관리', url: '/setting/device' },
            { label: '통합 설정', url: '/setting/commonSetting' },
          ],
        },
      ]
    }
  },
  mounted() {
    this.isMenuOpen = this.useCommonStore.getIsMenuOpen
    if (this.isMenuOpen) this.menuFlag = this.useCommonStore.getMenuFlag
  },
  methods: {
    changeMenuOpen() {
      if (this.isMenuOpen) this.menuFlag = ''

      this.useCommonStore.menuOpenChange()
      this.isMenuOpen = this.useCommonStore.getIsMenuOpen
    },
    changeMenuFlag(flag) {
      if (!this.isMenuOpen) this.changeMenuOpen()
      this.useCommonStore.menuFlagChange(flag)
      this.menuFlag = this.useCommonStore.getMenuFlag
    },
    logout() {
      if (confirm('로그아웃 하시겠습니까?')) {
        this.useStoreMember.logout()
      }
    },
    moveMenu(url) {
      router.push(url)
    },
  },
}
</script>
