import axios from '@/utils/axios_interceptor'

function requestDashBoardDeviceList() {
  return axios.get('/dash/getDashBoardDeviceList')
}

function requestDashBoardDeviceConfig(deviceID) {
  return axios.get('/dash/getDashBoardConfigData?deviceId=' + deviceID)
}

function requestDashBoardChart(deviceID, startDate, endDate) {
  return axios.get('/dash/getDashBoardChart?deviceId=' + deviceID + '&startDate=' + startDate + '&endDate=' + endDate)
}

function requestGetTrendData(deviceID, startDate, endDate) {
  return axios.get(`/dash/getTrendData?deviceId=${deviceID}&startDate=${startDate}&endDate=${endDate}`)
}

export { requestDashBoardDeviceList, requestDashBoardDeviceConfig, requestDashBoardChart, requestGetTrendData }
