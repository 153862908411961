<template>
  <div class="modal">
    <div class="overlay" @click="$emit('close-modal')" />
    <div class="modal-content dash">
      <form>
        <p class="title">{{ modalTitle }}</p>
        <input v-model="value" type="text" style="margin-right: 30px" />
        <img class="exit-btn" alt="이전 페이지" src="" @click="closeModal" />
        <button class="blue default_btn" style="height: 40px" @click="saveSetting">
          <span>적용</span>
        </button>
        <p style="margin-top: 20px">{{ subTitle }}</p>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import commonMixin from '@/mixin/commonMixin'
import { deviceStore } from '@/store/device.store'
import { memberStore } from '@/store/member.store'

export default defineComponent({
  mixins: [commonMixin],
  props: {
    modalDataType: {
      type: [Number, String],
      require: false,
      default: 0,
    },
    deviceID: {
      type: [Number, String],
      require: false,
      default: '',
    },
  },
  emits: ['close-modal', 'change-success'],
  setup() {
    const useStoreMember = memberStore()
    const useStoreDevice = deviceStore()
    return { useStoreMember, useStoreDevice }
  },
  data() {
    return {
      modalTitle: '',
      subTitle: '',
      min: 0,
      max: 0,

      protocolID: '',
      value: '',
    }
  },
  mounted() {
    switch (this.modalDataType) {
      case 0:
        this.modalTitle = '온도 설정 변경'
        this.protocolID = '82'
        this.min = 0
        this.max = 600
        this.subTitle = `※ 최소값 : ${this.min}, 최댓값 : ${this.max}`
        break
      case 1:
        this.modalTitle = '습도 설정 변경'
        this.protocolID = '83'
        this.min = 1
        this.max = 99
        this.subTitle = `※ 최소값 : ${this.min}, 최댓값 : ${this.max}`
        break
      case 2:
        this.modalTitle = '냉각 편차 변경'
        this.protocolID = '84'
        this.min = 0
        this.max = 600
        this.subTitle = `※ 최소값 : ${this.min}, 최댓값 : ${this.max}`
        break
      case 3:
        this.modalTitle = '제습 편차 변경'
        this.protocolID = '85'
        this.min = 0
        this.max = 1
        this.subTitle = `※ 최소값 : ${this.min}, 최댓값 : ${this.max}`
        break
      case 4:
        this.modalTitle = '난방 편차 변경'
        this.protocolID = '87'
        this.min = 1
        this.max = 200
        this.subTitle = `※ 최소값 : ${this.min}, 최댓값 : ${this.max}`
        break
      case 5:
        this.modalTitle = '가습 편차 변경'
        this.protocolID = '86'
        this.min = 1
        this.max = 50
        this.subTitle = `※ 최소값 : ${this.min}, 최댓값 : ${this.max}`
        break
      case 6:
        this.modalTitle = '정전 복귀'
        this.protocolID = '92'
        this.min = 0
        this.max = 600
        this.subTitle = `※ 최소값 : ${this.min}, 최댓값 : ${this.max}`
        break
      case 7:
        this.modalTitle = '팬 정지 지연 변경'
        this.protocolID = '91'
        this.min = 0
        this.max = 600
        this.subTitle = `※ 최소값 : ${this.min}, 최댓값 : ${this.max}`
        break
      case 8:
        this.modalTitle = '압축지연1 변경'
        this.protocolID = '88'
        this.min = 0
        this.max = 600
        this.subTitle = `※ 최소값 : ${this.min}, 최댓값 : ${this.max}`
        break
      case 9:
        this.modalTitle = '압축지연2 변경'
        this.protocolID = '89'
        this.min = 0
        this.max = 600
        this.subTitle = `※ 최소값 : ${this.min}, 최댓값 : ${this.max}`
        break
      case 10:
        this.modalTitle = '압축지연3 변경'
        this.protocolID = '90'
        this.min = 0
        this.max = 600
        this.subTitle = `※ 최소값 : ${this.min}, 최댓값 : ${this.max}`
        break
      case 11:
        this.modalTitle = '운전 모드 변경'
        this.protocolID = '126'
        this.min = 0
        this.max = 2
        this.subTitle = '0 : 자체, 1 : 원격(접점운전), 2 : 스케줄( STHAV-ST이상 )'
        break
    }
  },
  methods: {
    closeModal(e) {
      e.preventDefault()
      this.$emit('close-modal')
    },
    saveSetting(e) {
      e.preventDefault()
      if (this.value === '') {
        alert('값을 입력해 주세요.')
        return
      }

      if (this.min > this.value || this.max < this.value) {
        alert('값이 옳바르지 않습니다.')
        return
      }

      const mwData = {
        data: this.value,
        protocolID: parseInt(this.protocolID),
        deviceID: parseInt(this.deviceID),
      }
      this.useStoreDevice.reqChangeMwSetting(mwData).then((r) => {
        if (r) {
          alert('변경사항이 저장 되었습니다.')
          this.$emit('close-modal')
        }
      })
    },
  },
})
</script>
