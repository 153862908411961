<template>
  <div class="modal">
    <div class="overlay" @click="$emit('close-modal')" />
    <div class="modal-content" style="width: 290px; margin: 250px auto auto">
      <form>
        <p class="title">비밀번호 변경</p>
        <img class="exit-btn" alt="이전 페이지" src="" @click="closeModal" />

        <input v-model="password" class="form_pw" type="password" placeholder="비밀번호" autocomplete="off" maxlength="16" :class="{ error: !error.password }" />
        <input v-model="password_confirm" class="form_pw" type="password" placeholder="비밀번호 확인" autocomplete="off" maxlength="16" :class="{ error: !error.password_confirm }" />
        <hr />
        <p class="error_txt" v-html="error_text" />

        <div class="button-wrap">
          <button v-if="selectedItem !== null" class="blue default_btn" @click="memberModify">
            <span>수정</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { memberStore } from '@/store/member.store'
import commonMixin from '@/mixin/commonMixin'

export default defineComponent({
  mixins: [commonMixin],
  props: {
    selectedItem: {
      type: Object,
      default: null,
    },
  },
  emits: ['close-modal', 'change-success'],
  setup(props) {
    const computedValue = computed(() => {
      return props.selectedItem
    })

    const useStoreMember = memberStore()
    return { useStoreMember, computedValue }
  },
  data() {
    return {
      loginName: '',
      password: '',
      password_confirm: '',
      name: '',
      email: '',
      mobile: '',
      auth: '',
      companyName: '',

      isLeaveSite: false,
      isActive: false,

      error_text: '',

      error: {
        password: true,
        password_confirm: true,
      },
    }
  },
  mounted() {
    if (this.computedValue) {
      this.loginName = this.computedValue.loginName
      this.name = this.computedValue.fullName
      this.email = this.computedValue.email
      this.auth = this.computedValue.auth
      this.mobile = this.computedValue.mobile
      this.isActive = this.computedValue.isActive
    }
  },
  methods: {
    memberModify(e) {
      e.preventDefault()

      if (!this.checkValue()) return
      let credentials
      if (!confirm('해당 유저의 비밀번호를 수정 하시겠습니까?')) return
      if (this.password !== '' || this.password_confirm !== '') {
        // eslint-disable-next-line no-undef
        const encryptedPassword = CryptoJS.SHA256(this.password).toString()
        credentials = {
          id: this.computedValue.id,
          loginPw: encryptedPassword,
          fullName: this.name,
          email: this.email,
          mobile: this.mobile,
          auth: this.auth,
        }
      } else {
        credentials = {
          id: this.computedValue.id,
          fullName: this.name,
          email: this.email,
          mobile: this.mobile,
          auth: this.auth,
        }
      }

      this.useStoreMember.memberInfoChangeAdmin(credentials).then((result) => {
        if (result) {
          alert('비밀번호가 수정 되었습니다.')
          this.$emit('change-success')
          this.$emit('close-modal')
        }
      })
    },
    checkValue() {
      this.error_text = ''
      this.error = {
        password: true,
        password_confirm: true,
      }

      if (this.isEmpty(this.password)) {
        this.error_text += '※ 비밀번호를 입력해 주세요.<br>'
        this.error.password = false
      } else {
        if (!this.checkPwReg(this.password)) {
          this.error_text += '※ 비밀번호는 8 ~ 16자 영문, 숫자, 특수 문자 조합 이여야 합니다.<br>'
          this.error.password = false
        }
      }

      if (this.isEmpty(this.password_confirm)) {
        this.error_text += '※ 비밀번호 확인을 입력해 주세요.<br>'
        this.error.password_confirm = false
      } else {
        if (this.password !== this.password_confirm) {
          this.error_text += '※ 비밀번호와 비밀번호 확인이 일치하지 않습니다.<br>'
          this.error.password_confirm = false
        }
      }

      return this.checkAllTrue(this.error)
    },
    closeModal(e) {
      e.preventDefault()
      this.$emit('close-modal')
    },
  },
})
</script>
