<template>
  <div class="modal">
    <div class="overlay" @click="$emit('close-modal')" />
    <div class="modal-content" style="width: 290px">
      <form>
        <p v-if="selectedItem !== null" class="title">사용자 수정</p>
        <p v-if="selectedItem === null" class="title">사용자 추가</p>
        <img class="exit-btn" alt="이전 페이지" src="" @click="closeModal" />

        <input v-model="loginName" class="form_id" type="text" placeholder="아이디" maxlength="41" :disabled="selectedItem !== null" :class="{ error: !error.loginName }" />
        <input v-model="password" class="form_pw" type="password" placeholder="비밀번호" autocomplete="off" maxlength="16" :class="{ error: !error.password }" />
        <input v-model="password_confirm" class="form_pw" type="password" placeholder="비밀번호 확인" autocomplete="off" maxlength="16" :class="{ error: !error.password_confirm }" />
        <select v-model="auth" style="width: 70%" :class="{ error: !error.auth }" :disabled="loginName === 'admin'">
          <option value="">권한</option>
          <option value="ROLE_USER">사용자</option>
          <option value="ROLE_ADMIN">관리자</option>
        </select>
        <hr />

        <input v-model="name" class="form_id" type="text" placeholder="이름" maxlength="10" :class="{ error: !error.name }" />
        <input v-model="email" class="form_id" type="text" placeholder="이메일" autocomplete="off" :class="{ error: !error.email }" />
        <input v-model="mobile" class="form_id" type="text" placeholder="모바일" autocomplete="off" maxlength="16" :class="{ error: !error.mobile }" />
        <input v-model="companyName" class="form_id" type="text" placeholder="업체명" autocomplete="off" maxlength="16" />

        <hr />
        <p class="error_txt" v-html="error_text" />

        <div class="button-wrap">
          <button v-if="selectedItem === null" class="blue default_btn" @click="signUp">
            <span>추가</span>
          </button>
          <button v-if="selectedItem !== null" class="blue default_btn" @click="memberModify">
            <span>수정</span>
          </button>
          <button v-if="selectedItem !== null" class="blue default_btn" @click="activeMember">
            <span v-if="isActive">비 활성화</span>
            <span v-if="!isActive">활성화</span>
          </button>
          <button v-if="selectedItem !== null" class="blue default_btn" @click="memberActive">
            <span>로그아웃</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { memberStore } from '@/store/member.store'
import commonMixin from '@/mixin/commonMixin'

export default defineComponent({
  mixins: [commonMixin],
  props: {
    selectedItem: {
      type: Object,
      default: null,
    },
  },
  emits: ['close-modal', 'change-success'],
  setup(props) {
    const computedValue = computed(() => {
      return props.selectedItem
    })

    const useStoreMember = memberStore()
    return { useStoreMember, computedValue }
  },
  data() {
    return {
      loginName: '',
      password: '',
      password_confirm: '',
      name: '',
      email: '',
      mobile: '',
      auth: '',
      companyName: '',

      isLeaveSite: false,
      isActive: false,

      error_text: '',

      error: {
        loginName: true,
        password: true,
        password_confirm: true,
        name: true,
        email: true,
        mobile: true,
        auth: true,
      },
    }
  },
  mounted() {
    if (this.computedValue) {
      this.loginName = this.computedValue.loginName
      this.name = this.computedValue.fullName
      this.email = this.computedValue.email
      this.auth = this.computedValue.auth
      this.mobile = this.computedValue.mobile
      this.isActive = this.computedValue.isActive
    }
  },
  methods: {
    memberLogout(e) {
      e.preventDefault()
    },
    memberActive(e) {
      e.preventDefault()
    },
    memberModify(e) {
      e.preventDefault()

      if (!this.checkValue()) return
      let credentials
      if (!confirm('해당 유저를 수정 하시겠습니까?')) return
      if (this.password !== '' || this.password_confirm !== '') {
        // eslint-disable-next-line no-undef
        const encryptedPassword = CryptoJS.SHA256(this.password).toString()
        credentials = {
          id: this.computedValue.id,
          loginPw: encryptedPassword,
          fullName: this.name,
          email: this.email,
          mobile: this.mobile,
          auth: this.auth,
        }
      } else {
        credentials = {
          id: this.computedValue.id,
          fullName: this.name,
          email: this.email,
          mobile: this.mobile,
          auth: this.auth,
        }
      }

      this.useStoreMember.memberInfoChangeAdmin(credentials).then((result) => {
        if (result) {
          alert('유저가 수정 되었습니다.')
          this.$emit('change-success')
          this.$emit('close-modal')
        }
      })
    },
    signUp(e) {
      e.preventDefault()

      if (!this.checkValue()) return

      // eslint-disable-next-line no-undef
      const encryptedPassword = CryptoJS.SHA256(this.password).toString()
      const credentials = {
        loginName: this.loginName,
        loginPw: encryptedPassword,
        fullName: this.name,
        email: this.email,
        mobile: this.mobile,
        auth: this.auth,
        companyName: this.companyName,
      }

      this.useStoreMember.signUp(credentials).then((result) => {
        if (result) {
          alert('유저가 추가 되었습니다.')
          this.$emit('change-success')
          this.$emit('close-modal')
        }
      })
    },
    allLogout(e) {
      e.preventDefault()
    },
    activeMember(e) {
      e.preventDefault()
      if (this.isActive) {
        if (!confirm('해당 유저를 비 활성화 하시겠습니까?')) return
      } else {
        if (!confirm('해당 유저를 활성화 하시겠습니까?')) return
      }

      const credentials = {
        id: this.computedValue.id,
        isActive: !this.isActive,
      }

      this.useStoreMember.memberInfoChangeAdmin(credentials).then((result) => {
        if (result) {
          if (this.isActive) alert('비활성화 되었습니다.')
          else alert('활성화 되었습니다.')
          this.$emit('change-success')
          this.$emit('close-modal')
        }
      })
    },
    checkValue() {
      this.error_text = ''
      this.error = {
        loginName: true,
        password: true,
        password_confirm: true,
        name: true,
        email: true,
        mobile: true,
        auth: true,
      }

      if (this.isEmpty(this.loginName)) {
        this.error_text += '※ 아이디를 입력해 주세요.<br>'
        this.error.loginName = false
      } else {
        if (!this.checkIdReg(this.loginName)) {
          this.error_text += '※ 아이디는 영문자로 시작하며, 영문자 또는 숫자 6~20자  여야 합니다. <br>'
          this.error.loginName = false
        }
      }

      if (this.selectedItem !== null) {
        if (!this.isEmpty(this.password) || !this.isEmpty(this.password_confirm)) {
          if (this.isEmpty(this.password)) {
            this.error_text += '※ 비밀번호를 입력해 주세요.<br>'
            this.error.password = false
          } else {
            if (!this.checkPwReg(this.password)) {
              this.error_text += '※ 비밀번호는 8 ~ 16자 영문, 숫자, 특수 문자 조합 이여야 합니다.<br>'
              this.error.password = false
            }
          }

          if (this.isEmpty(this.password_confirm)) {
            this.error_text += '※ 비밀번호 확인을 입력해 주세요.<br>'
            this.error.password_confirm = false
          } else {
            if (this.password !== this.password_confirm) {
              this.error_text += '※ 비밀번호와 비밀번호 확인이 일치하지 않습니다.<br>'
              this.error.password_confirm = false
            }
          }
        }
      } else {
        if (this.isEmpty(this.password)) {
          this.error_text += '※ 비밀번호를 입력해 주세요.<br>'
          this.error.password = false
        } else {
          if (!this.checkPwReg(this.password)) {
            this.error_text += '※ 비밀번호는 8 ~ 16자 영문, 숫자, 특수 문자 조합 이여야 합니다.<br>'
            this.error.password = false
          }
        }

        if (this.isEmpty(this.password_confirm)) {
          this.error_text += '※ 비밀번호 확인을 입력해 주세요.<br>'
          this.error.password_confirm = false
        } else {
          if (this.password !== this.password_confirm) {
            this.error_text += '※ 비밀번호와 비밀번호 확인이 일치하지 않습니다.<br>'
            this.error.password_confirm = false
          }
        }
      }

      if (this.isEmpty(this.auth)) {
        this.error_text += '※ 권한을 선택 해 주세요.<br>'
        this.error.auth = false
      }

      if (this.isEmpty(this.name)) {
        this.error_text += '※ 이름을 입력해 주세요.<br>'
        this.error.name = false
      }

      if (this.isEmpty(this.email)) {
        this.error_text += '※ 이메일을 입력해 주세요.<br>'
        this.error.email = false
      } else {
        if (!this.checkEmailReg(this.email)) {
          this.error_text += '※ 이메일 형식이 올바르지 않습니다.<br>'
          this.error.email = false
        }
      }

      if (this.isEmpty(this.mobile)) {
        this.error_text += '※ 모바일을 입력해 주세요.<br>'
        this.error.mobile = false
      } else {
        if (!this.checkPhonReg(this.mobile)) {
          this.error_text += '※ 모바일이 올바르지 않습니다.'
          this.error.mobile = false
        }
      }

      return this.checkAllTrue(this.error)
    },
    closeModal(e) {
      e.preventDefault()
      this.$emit('close-modal')
    },
  },
})
</script>
