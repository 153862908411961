<template>
  <div id="app">
    <div v-if="useCommonStore.getIsLoading" class="loading-bar">
      <div class="bar" />
    </div>

    <router-view :key="$route.fullPath" name="app_router" />
  </div>
</template>
<script>
import { commonStore } from '@/store/common.store'

export default {
  name: 'App',
  components: {},
  setup() {
    const useCommonStore = commonStore()
    return { useCommonStore }
  },
  data: () => ({
  }),
  mounted() {
  },
  methods: {
  },
}
</script>
