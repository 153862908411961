<template>
  <div class="login_page">
    <div class="login_left_wrap">
      <div class="form_login">
        <p class="title">회원가입</p>
        <form>
          <div class="login_wrap">
            <div class="id_pw_wrap">
              <div class="input_row">
                <p>아이디</p>
                <input ref="et_id" v-model="username" class="form_id" type="text" placeholder="아이디를 입력해 주세요." maxlength="41" @focus="handleFocus" @blur="checkID" />
                <p v-if="idMsg" class="error_txt" style="margin: -20px 0 12px 0; color: red">{{ idMsg }}</p>
              </div>
              <div class="input_row">
                <p>비밀번호</p>
                <input ref="et_pw" v-model="password" class="form_pw" type="password" placeholder="비밀번호를 입력해 주세요." autocomplete="off" maxlength="16" @focus="handleFocus" @blur="checkPw" />
                <p v-if="pwMsg" class="error_txt" style="margin: -20px 0 12px 0; color: red">{{ pwMsg }}</p>
              </div>
              <div class="input_row">
                <p>비밀번호 확인</p>
                <input ref="et_pw2" v-model="password_confirm" class="form_pw" type="password" placeholder="비밀번호 확인을 입력해 주세요." autocomplete="off" maxlength="16" @focus="handleFocus" @blur="checkPw2" />
                <p v-if="pwMsg2" class="error_txt" style="margin: -20px 0 12px 0; color: red">{{ pwMsg2 }}</p>
              </div>
              <div class="input_row">
                <p>이름</p>
                <input ref="et_name" v-model="name" class="form_id" type="text" placeholder="이름을 입력해 주세요." maxlength="10" @focus="handleFocus" @blur="checkName" />
                <p v-if="nameMsg" class="error_txt" style="margin: -20px 0 12px 0; color: red">{{ nameMsg }}</p>
              </div>
              <div class="input_row">
                <p>이메일</p>
                <input ref="et_email" v-model="email" class="form_id" type="text" placeholder="이메일을 입력해 주세요." autocomplete="off" maxlength="45" @focus="handleFocus" @blur="checkEmail" />
                <p v-if="emailMsg" class="error_txt" style="margin: -20px 0 12px 0; color: red">{{ emailMsg }}</p>
              </div>
              <div class="input_row">
                <p>휴대폰 번호</p>
                <input ref="et_mobile" v-model="mobile" class="form_id" type="text" placeholder="휴대폰 번호를 입력해 주세요." autocomplete="off" maxlength="16" @focus="handleFocus" @blur="checkMobile" />
                <p v-if="mobileMsg" class="error_txt" style="margin: -20px 0 12px 0; color: red">{{ mobileMsg }}</p>
              </div>
              <div class="input_row">
                <p>권한</p>
                <input id="ROLE_USER1" v-model="auth" type="radio" name="ROLE" style="width: 30px; height: 14px" value="ROLE_USER1" checked />
                <label for="ROLE_USER1" style="color: white">일반 유저 - 읽기</label>
                <br />
                <input id="ROLE_USER2" v-model="auth" type="radio" name="ROLE" style="width: 30px; height: 14px" value="ROLE_USER2" />
                <label for="ROLE_USER2" style="color: white">일반 유저 - 읽기/쓰기</label>
                <br />
                <input id="ROLE_ADMIN" v-model="auth" type="radio" name="ROLE" style="width: 30px; height: 14px" value="ROLE_ADMIN" />
                <label for="ROLE_ADMIN" style="color: white">로컬 관리자</label>
              </div>
            </div>
            <button class="btn_login" @click="signUp">
              <span class="btn_text">회원 가입</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="login_right_wrap" />
  </div>
</template>
<script>
import { memberStore } from '@/store/member.store'
import commonMixin from '@/mixin/commonMixin'
import { router } from '@/router'

export default {
  name: 'SignUpView',
  mixins: [commonMixin],
  beforeRouteLeave(to, from, next) {
    const answer = window.confirm('데이터 저장이 되지 않았습니다. 이 페이지를 나가시겠습니까?')
    if (answer) {
      document.activeElement.blur()
      next()
    } else {
      next(false)
    }
  },
  setup() {
    const useStoreMember = memberStore()
    return { useStoreMember }
  },
  data() {
    return {
      username: '',
      password: '',
      password_confirm: '',
      name: '',
      email: '',
      mobile: '',
      auth: '',

      isLeaveSite: false,

      idMsg: '',
      pwMsg: '',
      pwMsg2: '',
      nameMsg: '',
      emailMsg: '',
      mobileMsg: '',
    }
  },
  mounted() {
    window.addEventListener('beforeunload', this.leave)
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.leave)
  },
  methods: {
    signUp(event) {
      event.preventDefault()

      if (!this.checkAll()) return

      // eslint-disable-next-line no-undef
      const encryptedPassword = CryptoJS.SHA256(this.password).toString()
      const credentials = {
        loginName: this.username,
        loginPw: encryptedPassword,
        fullName: this.name,
        email: this.email,
        mobile: this.mobile,
        auth: this.auth,
        isActive: false,
      }
      this.useStoreMember.signUp(credentials).then((r) => {
        if (r) {
          alert('가입이 신청이 완료 되었습니다.\n관리자 승인 시, 이용 가능 합니다.')
          window.removeEventListener('beforeunload', this.leave)
          router.push('/')
        }
      })
    },
    handleFocus(event) {
      $(event.target.parentNode).addClass('focus_on')
    },
    checkAll() {
      const functionsArray = [this.checkID(), this.checkPw(), this.checkPw2(), this.checkName(), this.checkEmail(), this.checkMobile()]
      return functionsArray.every((func) => func)
    },
    checkID() {
      const target = this.$refs.et_id.parentElement
      if (this.username === '') {
        this.idMsg = '※ 아이디를 입력 해 주세요.'
        this.setError(target)
        return false
      }
      if (!this.checkIdReg(this.username)) {
        this.idMsg = '※ 아이디는 영문자로 시작하며,\n영문자 또는 숫자 6~20자  여야 합니다.'
        this.setError(target)
        return false
      }
      this.idMsg = ''
      this.removeError(target)
      return true
    },
    checkPw() {
      if (this.password === '') {
        this.pwMsg = '※ 비밀번호를 입력 해 주세요.'
        this.setError(this.$refs.et_pw.parentElement)
        return false
      }
      if (!this.checkPwReg(this.password)) {
        this.pwMsg = '※ 비밀번호는 8 ~ 16자 영문, 숫자, 특수 문자 조합 이여야 합니다.'
        this.setError(this.$refs.et_pw.parentElement)
        return false
      }
      this.pwMsg = ''
      this.removeError(this.$refs.et_pw.parentElement)
      return true
    },
    checkPw2() {
      if (this.password_confirm === '') {
        this.pwMsg2 = '※ 비밀번호 확인을 입력 해 주세요.'
        this.setError(this.$refs.et_pw2.parentElement)
        return false
      }
      if (this.password !== this.password_confirm) {
        this.pwMsg2 = '※ 비밀번호와 비밀번호 확인이 일치하지 않습니다.'
        this.setError(this.$refs.et_pw2.parentElement)
        return false
      }
      this.pwMsg2 = ''
      this.removeError(this.$refs.et_pw2.parentElement)
      return true
    },
    checkName() {
      if (this.name === '') {
        this.nameMsg = '※ 이름을 입력해 주세요.'
        this.setError(this.$refs.et_name.parentElement)
        return false
      }
      this.nameMsg = ''
      this.removeError(this.$refs.et_name.parentElement)
      return true
    },
    checkEmail() {
      if (this.email === '') {
        this.emailMsg = '※ 이메일을 입력해 주세요.'
        this.setError(this.$refs.et_email.parentElement)
        return false
      }
      if (!this.checkEmailReg(this.email)) {
        this.emailMsg = '※ 이메일 형식이 올바르지 않습니다.'
        this.setError(this.$refs.et_email.parentElement)
        return false
      }
      this.emailMsg = ''
      this.removeError(this.$refs.et_email.parentElement)
      return true
    },
    checkMobile() {
      if (this.mobile === '') {
        this.mobileMsg = '※ 모바일을 입력해 주세요.'
        this.setError(this.$refs.et_mobile.parentElement)
        return false
      }
      if (!this.checkPhonReg(this.mobile)) {
        this.mobileMsg = '※ 모바일이 올바르지 않습니다.'
        this.setError(this.$refs.et_email.parentElement)
        return false
      }
      this.mobileMsg = ''
      this.removeError(this.$refs.et_mobile.parentElement)
      return true
    },
    leave(event) {
      event.preventDefault()
    },
    setError(target) {
      $(target).removeClass('focus_on')
      $(target).addClass('error_on')
    },
    removeError(target) {
      $(target).removeClass('error_on')
      $(target).removeClass('focus_on')
    },
  },
}
</script>

<style scoped></style>
