<template>
  <div class="main_wrap">
    <div class="left-div">
      <div class="menu-item">
        <p :class="{ on: selectedMenu === 0 }" @click="changeMenu(0)">WebServer 정보</p>
        <p :class="{ on: selectedMenu === 1 }" @click="changeMenu(1)">네트워크 정보</p>
        <p :class="{ on: selectedMenu === 2 }" @click="changeMenu(2)">장비 등록 및 수정</p>
        <p :class="{ on: selectedMenu === 3 }" @click="changeMenu(3)">사용자 관리</p>
      </div>
    </div>
    <div class="right-div">
      <div class="top-div">
        <p class="title"># 설정 - {{ title }}</p>
      </div>
      <div class="bottom-div">
        <WebServerInfo v-if="selectedMenu === 0" />
        <NetworkInfo v-if="selectedMenu === 1" />
        <DeviceSetting v-if="selectedMenu === 2" />
        <UserSetting v-if="selectedMenu === 3" />
      </div>
    </div>
  </div>
</template>
<script>
import commonMixin from '@/mixin/commonMixin'
import WebServerInfo from '@/components/pc/setting/webserverInfo/WebServerInfo.vue'
import NetworkInfo from '@/components/pc/setting/networkInfo/NetworkInfo.vue'
import DeviceSetting from '@/components/pc/setting/device/DeviceSetting.vue'
import UserSetting from '@/components/pc/setting/userSetting/UserSetting.vue'

export default {
  components: { UserSetting, DeviceSetting, NetworkInfo, WebServerInfo },
  mixins: [commonMixin],
  setup() {},
  data() {
    return {
      selectedMenu: 0,
      title: '',
    }
  },
  mounted() {
    this.changeMenu(0)
  },
  methods: {
    changeMenu(num) {
      this.selectedMenu = num
      if (num === 0) this.title = 'WebServer 정보'
      else if (num === 1) this.title = '네트워크 정보'
      else if (num === 2) this.title = '장비 등록 및 수정'
      else if (num === 3) this.title = '사용자 관리'
    },
  },
}
</script>
