<template>
  <div class="setting_user_wrap">
    <div class="top_wrap">
      <p><span class="title">사용자 관리</span> <span class="menu">Setting</span></p>
      <hr />
    </div>
    <div class="bottom_wrap">
      <button class="blue default_btn" @click="memberModal">
        <span>사용자 추가+</span>
      </button>
      <CommonTable :item-option="itemOption" :item-list="userList" :page-info="pageInfo" @row-clicked="handleRowClick" />
      <CommonPaging v-if="userList.length !== 0" :page-info="pageInfo" @move-page="handlePage" />
    </div>

    <UserEditModal v-if="isModalViewed" :selected-item="selectedItem" @close-modal="isModalViewed = false" @change-success="changeData" />
  </div>
</template>
<script>
import CommonTable from '@/components/pc/common/CommonTable.vue';
import UserEditModal from '@/components/pc/setting/userSetting/modal/UserEditModal.vue';
import CommonPaging from '@/components/pc/common/CommonPaging.vue';
import { memberStore } from '@/store/member.store';
import commonMixin from '@/mixin/commonMixin';

export default {
  components: {
    CommonPaging,
    CommonTable,
    UserEditModal,
  },
  mixins: [commonMixin],
  setup() {
    const useStoreMember = memberStore();
    return { useStoreMember };
  },
  data() {
    return {
      page: 1,
      pageInfo: [],

      itemOption: [
        { name: 'NO', width: '4%', target: 'no' },
        { name: 'ID', width: '10%', target: 'loginName' },
        { name: '이름', width: '10%', target: 'fullName' },
        { name: '모바일', width: '10%', target: 'mobile' },
        { name: '이메일', width: '16%', target: 'email' },
        { name: '가입 일자', width: '10%', target: 'createdDate' },
        { name: '활성 여부', width: '5%', target: 'isActive', isStatus: true, trueValue: '활성', falseValue: '비활성' },
      ],
      userList: [],
      selectedItem: null,
      isModalViewed: false,
    };
  },
  mounted() {
    this.getMemberList();
  },
  methods: {
    getMemberList() {
      this.useStoreMember.reqMemberList(this.page).then((r) => {
        if (r) {
          this.userList = this.useStoreMember.getMemberList;
          this.pageInfo = this.useStoreMember.getPageInfo;
        }
      });
    },
    handleRowClick(rowIndex) {
      this.selectedItem = this.userList[rowIndex];
      this.isModalViewed = true;
    },
    handlePage(data) {
      if (this.page !== data) {
        this.page = data;
        this.getMemberList();
      }
    },
    memberModal() {
      this.selectedItem = null;
      this.isModalViewed = true;
    },
    changeData() {
      this.page = 1;
      this.getMemberList();
    },
  },
};
</script>
