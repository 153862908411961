import { memberStore } from '@/store/member.store'
import LoginView from '@/views/pc/member/Login.vue'
import SignView from '@/views/pc/member/SignUp.vue'

const onlyNotAuth = (to, from, next) => {
  const userStore = memberStore()
  if (!userStore.isAuthenticated) next()
  else next('/')
}

export default [
  {
    path: '/login',
    components: {
      app_router: LoginView,
    },
    beforeEnter: onlyNotAuth,
    meta: {
      title: '로그인',
    },
  },
  {
    path: '/signUp',
    components: {
      app_router: SignView,
    },
    beforeEnter: onlyNotAuth,
    meta: {
      title: '회원가입',
    },
  },
]
