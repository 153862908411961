<template>
  <div class="container">
    <div class="box" style="flex: 3">
      <div class="info-wrap">
        <p class="title">주요 상태</p>
        <hr />
        <div style="display: flex; justify-content: space-between">
          <div class="box100201" style="margin-right: 4px; background: #e3d294; position: relative">
            <span style="position: absolute; left: 10px; font-size: 18px; font-weight: 500; color: #b99d3c; top: 12px">온도</span>
            <img :src="require('@/assets/images/ico_tmp.png')" alt="" style="position: absolute; right: 10px" />
            <p style="width: 90%; position: absolute; text-align: center; top: calc(50% - 20px); font-size: 30px; font-weight: 700; color: #000000">
              <template v-if="configData != null">{{ configData.v4 / 10 }}</template>
              <template v-else>-</template>
              ℃
            </p>
          </div>
          <div class="box100201" style="margin-left: 10px; background: #84c8c0; position: relative">
            <span style="position: absolute; left: 10px; font-size: 18px; font-weight: 500; color: #4b7a75; top: 12px">습도</span>
            <img :src="require('@/assets/images/ico_hum.png')" alt="" style="position: absolute; right: 10px" />
            <p style="width: 90%; position: absolute; text-align: center; top: calc(50% - 20px); font-size: 30px; font-weight: 700; color: #000000">
              <template v-if="configData != null">{{ configData.v5 }}</template>
              <template v-else>-</template>
              %
            </p>
          </div>
        </div>

        <div class="container">
          <div class="box">
            <p>이슬점온도</p>
            <p>
              <template v-if="configData != null">{{ configData.v7 }}</template>
              <template v-else>-</template>
              ℃
            </p>
          </div>
        </div>
      </div>
      <template v-if="configData != null">
        <div v-if="configData.v6 === 0" class="info-wrap" style="height: 230px; background: #36e7ff1a">
          <p class="title">경보 상태 | <span style="color: white; font-weight: 300"> 센서모듈 이상</span></p>
          <hr />
          <div>
            <div class="status-indicator green">
              <span>정상</span>
            </div>
          </div>
        </div>
        <div v-else class="info-wrap" style="height: 230px; background: #dc52491a">
          <p class="title">경보 상태 | <span style="color: white; font-weight: 300"> 센서모듈 이상</span></p>
          <hr />
          <div>
            <div class="status-indicator red">
              <span>이상</span>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="info-wrap" style="height: 230px; background: #36e7ff1a">
          <p class="title">경보 상태 | <span style="color: white; font-weight: 300"> 센서모듈 이상</span></p>
          <hr />
        </div>
      </template>
    </div>
  </div>
  <div class="info-wrap">
    <AreaChart ref="chartRef" />
  </div>
</template>
<script>
import AreaChart from '@/components/pc/dashboard/chart/AreaChart.vue'

export default {
  name: 'DetailType100101',
  components: { AreaChart },
  props: {
    configData: {
      type: Object,
      required: false,
      default: null,
    },
    chartData: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  watch: {
    chartData() {
      this.$refs.chartRef.dataChange(this.chartData)
    },
  },
  methods: {
    changeData() {},
    showSettingModal(type) {
      if (this.editable) {
        this.modalDataType = type
        this.isModalViewed = true
      }
    },
  },
}
</script>
