<template>
  <div class="mobile login_page">
    <div class="login_left_wrap">
      <div class="form_login">
        <div class="title">Login</div>
        <form>
          <div class="login_wrap">
            <div class="id_pw_wrap">
              <div class="input_row">
                <p>아이디</p>
                <input ref="et_id" v-model="username" class="form_id" type="text" placeholder="아이디를 입력해 주세요." maxlength="41" @focus="handleFocus" @blur="handleBlur" />
              </div>
              <div class="input_row">
                <p>비밀번호</p>
                <input ref="et_pw" v-model="password" class="form_pw" type="password" placeholder="비밀번호를 입력해 주세요." autocomplete="off" maxlength="16" @focus="handleFocus" @blur="handleBlur" />
              </div>
            </div>
            <p v-if="error_text" class="error_txt">{{ error_text }}</p>
            <button class="btn_login" @click="login">
              <span class="btn_text">Login</span>
            </button>
            <p class="version">ver {{ versionText }}</p>
            <p class="version">mac : {{ macAddress }}</p>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { memberStore } from '@/store/member.store';
import { commonStore } from '@/store/common.store';

export default {
  name: 'LoginView',
  setup() {
    const useStoreMember = memberStore();
    const useStoreCommon = commonStore();
    return { useStoreMember, useStoreCommon };
  },
  data() {
    return {
      versionText: `${process.env.VUE_APP_VERSION}`,
      macAddress: '',

      username: '',
      password: '',
      error_text: '',
    };
  },
  mounted() {
    this.useStoreCommon.reqGetMacAddress().then((r) => {
      if (r) this.macAddress = this.useStoreCommon.getMacAddress;
    });
  },
  methods: {
    login(event) {
      event.preventDefault();
      if (this.username === '') {
        this.error_text = '아이디를 입력해 주세요.';
        this.$refs.et_id.focus();
        return;
      }
      if (this.password === '') {
        this.error_text = '비밀번호를 입력해 주세요.';
        this.$refs.et_pw.focus();
        return;
      }
      // eslint-disable-next-line no-undef
      const encryptedPassword = CryptoJS.SHA256(this.password).toString();
      const credentials = {
        loginName: this.username,
        loginPw: encryptedPassword,
      };
      this.useStoreMember.mobileLogin(credentials).then((msg) => (this.error_text = msg));
    },
    handleFocus(event) {
      $(event.target.parentNode).addClass('focus_on');
    },
    handleBlur(event) {
      $(event.target.parentNode).removeClass('focus_on');
    },
  },
};
</script>
