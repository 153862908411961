<template>
  <div class="info-wrap">
    <div class="bottom_wrap">
      <button class="blue default_btn add_btn" @click="memberModal">
        <span>사용자 추가+</span>
      </button>

      <div class="table-wrap">
        <table>
          <colgroup>
            <col style="width: 50px" />
            <col style="width: 120px" />
            <col />
            <col style="width: 100px" />
            <col style="width: 100px" />
            <col />
            <col style="width: 100px" />
            <col />
            <col style="width: 100px" />
          </colgroup>
          <tr>
            <th>no</th>
            <th>이름</th>
            <th>ID</th>
            <th>PW</th>
            <th>권한</th>
            <th>전화번호</th>
            <th>장비 권한</th>
            <th>업체명</th>
            <th>삭제</th>
          </tr>
          <tr v-for="(item, index) in userList" :key="item.id">
            <td>
              <span>{{ pageInfo.totalCnt - index - 10 * pageInfo.pageIndex }}</span>
            </td>
            <td>
              <span>{{ item.fullName }}</span>
            </td>
            <td>
              <span>{{ item.loginName }}</span>
            </td>
            <td>
              <button class="blue default_btn" style="height: 30px" @click="changePwForm(index)">
                <span>변경</span>
              </button>
            </td>
            <td>
              <span v-if="item.auth === 'ROLE_MASTER'">관리자</span>
              <span v-else-if="item.auth === 'ROLE_USER'">사용자</span>
            </td>
            <td>
              <span>{{ item.mobile }}</span>
            </td>
            <td>
              <button class="blue default_btn" style="height: 30px" @click="changeDeviceForm(index)">
                <span>설정</span>
              </button>
            </td>
            <td>
              <span>{{ item.companyName }}</span>
            </td>
            <td>
              <button class="red default_btn" style="height: 30px" @click="changeSetting">
                <span>삭제</span>
              </button>
            </td>
          </tr>

          <tr v-if="userList.length === 0">
            <td :colspan="itemOption.length">데이터가 없습니다.</td>
          </tr>
        </table>
      </div>

      <CommonPaging v-if="userList.length !== 0" :page-info="pageInfo" @move-page="handlePage" />
    </div>
  </div>

  <UserEditModal v-if="isUserEditModal" :selected-item="selectedItem" @close-modal="isUserEditModal = false" @change-success="changeData" />
  <PasswordEditModal v-if="isPasswordModal" :selected-item="selectedItem" @close-modal="isPasswordModal = false" />
  <DeviceAuthModal v-if="isDeviceModal" :selected-item="selectedItem" @close-modal="isDeviceModal = false" />
</template>
<script>
import UserEditModal from '@/components/pc/setting/userSetting/modal/UserEditModal.vue'
import CommonPaging from '@/components/pc/common/CommonPaging.vue'
import { memberStore } from '@/store/member.store'
import commonMixin from '@/mixin/commonMixin'
import PasswordEditModal from '@/components/pc/setting/userSetting/modal/PasswordEditModal.vue'
import DeviceAuthModal from '@/components/pc/setting/userSetting/modal/DeviceAuthModal.vue'

export default {
  components: {
    DeviceAuthModal,
    PasswordEditModal,
    CommonPaging,
    UserEditModal,
  },
  mixins: [commonMixin],
  setup() {
    const useStoreMember = memberStore()
    return { useStoreMember }
  },
  data() {
    return {
      page: 1,
      pageInfo: [],

      itemOption: [
        { name: 'NO', width: '4%', target: 'no' },
        { name: 'ID', width: '10%', target: 'loginName' },
        { name: '이름', width: '10%', target: 'fullName' },
        { name: '모바일', width: '10%', target: 'mobile' },
        { name: '이메일', width: '16%', target: 'email' },
        { name: '가입 일자', width: '10%', target: 'createdDate' },
        { name: '활성 여부', width: '5%', target: 'isActive', isStatus: true, trueValue: '활성', falseValue: '비활성' },
      ],
      userList: [],
      selectedItem: null,

      isUserEditModal: false,
      isPasswordModal: false,
      isDeviceModal: false,
    }
  },
  mounted() {
    this.getMemberList()
  },
  methods: {
    getMemberList() {
      this.useStoreMember.reqMemberList(this.page).then((r) => {
        if (r) {
          this.userList = this.useStoreMember.getMemberList
          this.pageInfo = this.useStoreMember.getPageInfo
        }
      })
    },
    handleRowClick(rowIndex) {
      this.selectedItem = this.userList[rowIndex]
      this.isUserEditModal = true
    },
    handlePage(data) {
      if (this.page !== data) {
        this.page = data
        this.getMemberList()
      }
    },
    memberModal() {
      this.selectedItem = null
      this.isUserEditModal = true
    },
    changeData() {
      this.page = 1
      this.getMemberList()
    },
    changePwForm(rowIndex) {
      this.selectedItem = this.userList[rowIndex]
      this.isPasswordModal = true
    },
    changeDeviceForm(rowIndex) {
      if (this.userList[rowIndex].auth === 'ROLE_MASTER') {
        alert('관리자의 장비권한은 설정 할 수 없습니다.')
        return
      }

      this.selectedItem = this.userList[rowIndex]
      this.isDeviceModal = true
    },
  },
}
</script>
