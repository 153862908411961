import { defineStore } from 'pinia'
import * as CommonAPI from '@/apis/common.api'

export const commonStore = defineStore('common', {
  state: () => ({
    isMenuOpen: false,
    menuFlag: '',
    isLoading: false,
    menuTitle: '',

    macAddress: '',
    serverInfo: null
  }),
  getters: {
    getIsMenuOpen: (state) => {
      return state.isMenuOpen
    },
    getMenuFlag: (state) => {
      return state.menuFlag
    },
    getIsLoading: (state) => {
      return state.isLoading
    },
    getMenuTitle: (state) => {
      return state.menuTitle
    },
    getMacAddress: (state) => {
      return state.macAddress
    },
    getServerInfo:(state) =>{
      return state.serverInfo
    }
  },
  actions: {
    async reqGetMacAddress() {
      try {
        const response = await CommonAPI.requestGetMacAddress()
        if (response != null && response.status === 200 && response.data.result) {
          this.macAddress = response.data.data
          return true
        }
      } catch (e) {
        console.log(e)
      }
    },
    async reqGetServerInfo(){
      try{
        const response = await CommonAPI.requestGetServerInfo()
        if (response != null && response.status === 200 && response.data.result) {
          this.serverInfo = response.data.data
          return true
        }
      }catch (e) {
        console.log(e)
      }
    },
    menuOpenChange() {
      this.isMenuOpen = !this.isMenuOpen
    },
    menuFlagChange(flag) {
      this.menuFlag = flag
    },
    startLoading() {
      this.isLoading = true
    },
    stopLoading() {
      this.isLoading = false
    },
    setMenuTitle(str) {
      this.menuTitle = str
    },
  },
})
