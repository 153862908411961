import { createRouter, createWebHistory } from 'vue-router'

import MemberRouter from '@/router/pc/member/router'
import MMemberRouter from '@/router/mobile/member/router'
import DashRouter from '@/router/pc/dashboard/router'
import MDashRouter from '@/router/mobile/dashboard/router'
import HistoryRouter from '@/router/pc/history/router'
import SettingRouter from '@/router/pc/setting/router'

const routes = [...MemberRouter, ...MMemberRouter, ...DashRouter, ...MDashRouter, ...HistoryRouter, ...SettingRouter, ...MemberRouter, { path: '/', redirect: '/dash' }]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(() => {})

router.afterEach((to) => {
  const title = to.meta.title
  if (title) document.title = title
})

export { router }
