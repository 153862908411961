import { defineStore } from 'pinia'
import { router } from '@/router'
import storageAuth from '@/utils/storage.util'
import * as MemberAPI from '@/apis/member_api'

export const memberStore = defineStore('member', {
  state: () => ({
    accessToken: storageAuth.getAccessToken(),
    member: storageAuth.getMemberInfoStorage(),
    memberList: [],
    pageInfo: [],
  }),
  getters: {
    isAuthenticated: (state) => {
      return !!state.accessToken
    },
    getMemberInfo: (state) => {
      return state.member
    },
    getAccessToken: (state) => {
      return state.accessToken.accessToken
    },
    getRefreshToken: (state) => {
      return state.accessToken.refreshToken
    },
    getMemberList: (state) => {
      return state.memberList
    },
    getPageInfo: (state) => {
      return state.pageInfo
    },
  },
  actions: {
    async signUp(memberForm) {
      try {
        const response = await MemberAPI.requestSignUp(memberForm)
        if (response != null && response.status === 200 && response.data.result) return response.data.result
        else alert(response.data.msg)
      } catch (e) {
        console.log(e)
      }
    },
    async login(memberForm) {
      try {
        const response = await MemberAPI.requestLogin(memberForm)
        if (response.data.result) {
          this.member = { memberNickName: response.data.data.nickName, memberAuth: response.data.data.auth }
          storageAuth.setMemberInfoStorage(this.member)
          this.accessToken = response.data.data.tokenVO
          storageAuth.setAccessToken(this.accessToken)

          await router.push('/')
        } else {
          return response.data.msg
        }
      } catch (e) {
        return '네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다.'
      }
    },
    async mobileLogin(memberForm) {
      try {
        const response = await MemberAPI.requestLogin(memberForm)
        if (response.data.result) {
          this.member = { memberNickName: response.data.data.nickName, memberAuth: response.data.data.auth }
          storageAuth.setMemberInfoStorage(this.member)
          this.accessToken = response.data.data.tokenVO
          storageAuth.setAccessToken(this.accessToken)

          await router.push('/mobile/dash')
        } else {
          return response.data.msg
        }
      } catch (e) {
        return '네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다.'
      }
    },
    async logout() {
      try {
        await MemberAPI.requestLogout()
      } catch (e) {
        console.error(e)
      }
      storageAuth.deleteAllMemberInfo()
      this.member = null
      this.accessToken = null
      window.location.reload()
    },
    async reissueToken() {
      const response = await MemberAPI.requestReissueToken()
      if (response !== null && response.data.result) {
        this.accessToken.accessToken = response.data.data.accessToken
        if (response.data.data.refreshToken !== null) this.accessToken.refreshToken = response.data.data.refreshToken

        storageAuth.setAccessToken(this.accessToken)
      }
    },

    async reqMemberList(page) {
      try {
        const response = await MemberAPI.requestMemberList(page)
        if (response !== null && response.status === 200) {
          if (response.data.result) {
            this.memberList = response.data.data
            this.pageInfo = response.data.pageInfo
            return true
          } else alert(response.data.msg)
        }
      } catch (e) {
        console.log(e)
        alert('현재 서버와 통신이 원활하지 않습니다.')
      }
      return false
    },

    async reqMemberListAll() {
      try {
        const response = await MemberAPI.requestMemberListAll()
        if (response !== null && response.status === 200 && response.data.result) return response.data.data
      } catch (e) {
        console.log(e)
        alert('현재 서버와 통신이 원활하지 않습니다.')
      }
      return false
    },

    async memberInfoChangeAdmin(memberForm) {
      try {
        const response = await MemberAPI.requestMemberInfoChangeAdmin(memberForm)
        if (response != null && response.data.result) {
          return response.data.result
        } else alert(response.data.msg)
      } catch (e) {
        alert('네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다.')
      }
    },
  },
})
