<template>
  <div class="mobile">
    <LeftMenu />
    <router-view :key="$route.fullPath" class="main_content" name="main_router" />
  </div>
</template>

<script>
import LeftMenu from '@/components/mobile/common/MenuComponent.vue'

export default {
  name: 'MMainView',
  components: { LeftMenu },
  data() {
    return {}
  },
  mounted() {},
  created() {},
  methods: {},
}
</script>

<style Lang="scss">
@import '@/assets/css/mobile-main.css';
</style>
