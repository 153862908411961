<template>
  <div class="info-wrap">
    <p>검색 조건</p>

    <div class="container" style="align-items: center">
      <div class="box" style="flex: none; margin-right: 40px">
        <div class="search_date_form">
          <VueDatePicker v-model="searchStartDate" class="search_date" placeholder="검색 시작 날짜" format="yyyy-MM-dd HH:mm:ss" />
          <VueDatePicker v-model="searchEndDate" class="search_date" placeholder="검색 종료 날짜" format="yyyy-MM-dd HH:mm:ss" />
        </div>
      </div>
      <div class="box" style="flex: none; margin-right: 80px">
        <div class="checkbox-container">
          <label>
            <input type="checkbox" /> 실내 온도
            <span class="color-box" style="background-color: red"></span>
          </label>
          <label>
            <input type="checkbox" /> 실내 습도
            <span class="color-box" style="background-color: blue"></span>
          </label>
          <label>
            <input type="checkbox" /> SA 온도
            <span class="color-box" style="background-color: green"></span>
          </label>
          <label>
            <input type="checkbox" /> SA 습도
            <span class="color-box" style="background-color: yellow"></span>
          </label>
        </div>
      </div>
      <div class="box">
        <button class="blue default_btn" style="width: 130px" @click="search">
          <span>검색</span>
        </button>
        <br />
        <button class="grey default_btn" style="margin-top: 14px; width: 130px" @click="exportExcel">
          <span>엑셀 출력</span>
        </button>
      </div>
    </div>
  </div>
  <div class="info-wrap">
    <CommonTable :item-option="itemOption" :item-list="historyList" :page-info="pageInfo" @row-clicked="handleRowClick" />
    <CommonPaging v-if="historyList.length !== 0" :page-info="pageInfo" @move-page="handlePage" />
  </div>
</template>
<script>
import commonMixin from '@/mixin/commonMixin'
import { dashStore } from '@/store/dash.store'
import { commonStore } from '@/store/common.store'
import CommonPaging from '@/components/pc/common/CommonPaging.vue'
import CommonTable from '@/components/pc/common/CommonTable.vue'

export default {
  components: { CommonTable, CommonPaging },
  mixins: [commonMixin],
  setup() {
    const useStoreDash = dashStore()
    const useCommonStore = commonStore()

    return { useStoreDash, useCommonStore }
  },
  data() {
    return {
      searchStartDate: '',
      searchEndDate: '',

      page: 1,
      pageInfo: [],
      historyList: [
        { name: '메인 팬 이상', createdTime: '2023-09-09 15:24:33', releaseTime: '', isRed: true },
        { name: '난방 히터 이상', createdTime: '2023-09-09 13:44:03', releaseTime: '2023-09-09 14:22:31' },
        { name: '난방 히터 이상', createdTime: '2023-09-09 12:26:22', releaseTime: '', isRed: true },
        { name: '메인 팬 이상', createdTime: '2023-09-09 10:42:33', releaseTime: '2023-09-09 11:04:30' },
      ],
      itemOption: [
        { name: 'NO', width: '4%', target: 'no' },
        { name: '경보 내역', width: '20%', target: 'name' },
        { name: '발생 시간', width: '10%', target: 'createdTime' },
        { name: '해제 시간', width: '10%', target: 'releaseTime' },
      ],
    }
  },
  mounted() {},
  methods: {
    search(e) {
      e.preventDefault()
    },
    exportExcel(e) {
      e.preventDefault()
    },
    handlePage(data) {
      console.log(data)
    },
    handleRowClick(rowIndex) {
      console.log(rowIndex)
    },
  },
}
</script>
