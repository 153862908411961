import { defineStore } from 'pinia'
import * as DeviceAPI from '@/apis/device_api'

export const deviceStore = defineStore('device', {
  state: () => ({
    pageInfo: [],
    deviceList: [],
    deviceTypeList: [],
    deviceModelList: [],
    deviceAuthList: [],
    alertHistoryList:[],

    tableItem: [],

    alarmList: [],
    alarmMemberList: [],

    page: 0,
  }),
  getters: {
    getAlertHistoryList:(state) =>{
      return state.alertHistoryList
    },
    getDeviceTypeList: (state) => {
      return state.deviceTypeList
    },
    getDeviceAuthList: (state) => {
      return state.deviceAuthList
    },
    getDeviceList: (state) => {
      return state.deviceList
    },
    getPageInfo: (state) => {
      return state.pageInfo
    },
    getAlarmList: (state) => {
      return state.alarmList
    },
    getAlarmMemberList: (state) => {
      return state.alarmMemberList
    },
    getDeviceModelList: (state) => {
      return state.deviceModelList
    },
  },
  actions: {
    async reqDeviceTypeList() {
      try {
        const response = await DeviceAPI.requestDeviceTypeList()
        if (response != null && response.status === 200) {
          if (response.data.result) {
            this.deviceTypeList = response.data.data
            return true
          } else alert(response.data.msg)
        }
      } catch (e) {
        console.log(e)
      }
      return false
    },
    async addDeviceAuth(data) {
      try {
        const response = await DeviceAPI.requestAddDeviceAuth(data)
        if (response != null && response.status === 200) {
          if (response.data.result) return true
          else alert(response.data.msg)
        }
      } catch (e) {
        console.log(e)
      }
      return false
    },
    async reqDeleteDeviceAuth(id) {
      try {
        const response = await DeviceAPI.requestDeleteDeviceAuth(id)
        if (response != null && response.status === 200) {
          if (response.data.result) return true
          else alert(response.data.msg)
        }
      } catch (e) {
        console.log(e)
      }
    },
    async reqDeviceAuthList(id) {
      try {
        const response = await DeviceAPI.requestDeviceAuthList(id)
        if (response != null && response.status === 200) {
          if (response.data.result) {
            this.deviceAuthList = response.data.data
            return true
          } else alert(response.data.msg)
        }
      } catch (e) {
        console.log(e)
      }
    },
    async reqDeviceModelList(code) {
      try {
        const response = await DeviceAPI.requestDeviceModelList(code)
        if (response != null && response.status === 200) {
          if (response.data.result) {
            this.deviceModelList = response.data.data
            return true
          } else alert(response.data.msg)
        }
      } catch (e) {
        console.log(e)
      }
      return false
    },
    async reqChangeMwSetting(data) {
      try {
        const response = await DeviceAPI.requestChangeMwSetting(data)
        if (response != null && response.status === 200) {
          if (response.data.result) return true
          else alert(response.data.msg)
        }
      } catch (e) {
        console.log(e)
      }
    },
    async reqAlertHistoryPaging(page, deviceID){
      try {
        const response = await DeviceAPI.requestAlertHistoryListPaging(page, deviceID)
        if (response != null && response.status === 200) {
          if (response.data.result) {
            this.alertHistoryList = response.data.data
            this.pageInfo = response.data.pageInfo
            this.page = page
            return true
          } else alert(response.data.msg)
        }
      } catch (e) {
        console.log(e)
      }
      return false
    },
    async reqDeviceListPaging(page) {
      try {
        const response = await DeviceAPI.requestDeviceListPaging(page)
        if (response != null && response.status === 200) {
          if (response.data.result) {
            this.deviceList = response.data.data
            this.pageInfo = response.data.pageInfo
            this.page = page
            return true
          } else alert(response.data.msg)
        }
      } catch (e) {
        console.log(e)
      }
      return false
    },

    async reqDeviceList() {
      const response = await DeviceAPI.requestDeviceList()
      if (response !== null && response.data.result) {
        if (response.data.result) {
          this.deviceList = response.data.data
          return true
        } else alert(response.data.msg)
      }
      return false
    },

    async reqAddDevice(deviceData) {
      try {
        const response = await DeviceAPI.requestAddDevice(deviceData)
        if (response != null && response.status === 200) {
          if (response.data.result) return true
          else alert(response.data.msg)
        }
      } catch (e) {
        console.log(e)
      }
      return false
    },

    async reqModifyDevice(deviceData) {
      try {
        const response = await DeviceAPI.requestModifyDevice(deviceData)
        if (response != null && response.status === 200) {
          if (response.data.result) return true
          else alert(response.data.msg)
        }
      } catch (e) {
        console.log(e)
      }
      return false
    },

    async reqDeleteDevice(deviceData) {
      try {
        const response = await DeviceAPI.requestDeleteDevice(deviceData)
        if (response != null && response.status === 200) {
          if (response.data.result) return true
          else alert(response.data.msg)
        }
      } catch (e) {
        console.log(e)
      }
      return false
    },

    async reqGetAlarmList(deviceData) {
      try {
        const response = await DeviceAPI.requestAlarmList(deviceData)
        if (response != null && response.status === 200) {
          if (response.data.result) {
            this.alarmList = response.data.data
            this.alarmMemberList = response.data.subData
            return true
          } else alert(response.data.msg)
        }
      } catch (e) {
        console.log(e)
      }
      return false
    },

    async reqDeviceServiceLogList(startDate, endDate, deviceId, page) {
      const response = await DeviceAPI.requestDeviceServiceLogList(startDate, endDate, deviceId, page)
      if (response !== null && response.data.result) {
        this.tableItem = response.data.data
        this.totalPage = response.data.totalPage
        this.page = page
        return true
      }
    },
    async reqDeviceServiceLogExcel(startDate, endDate, deviceId) {
      await DeviceAPI.requestDeviceServiceLogExcel(startDate, endDate, deviceId).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'excel.xlsx')
        document.body.appendChild(link)
        link.click()
      })
    },
    async reqAddDeviceServiceLog(deviceData) {
      const response = await DeviceAPI.requestAddDeviceServiceLog(deviceData)
      if (response !== null && response.data.result) {
        return true
      }
    },
    async reqRemoveDeviceServiceLog(deviceData) {
      const response = await DeviceAPI.requestRemoveDeviceServiceLog(deviceData)
      if (response !== null && response.data.result) {
        return true
      }
    },
    async reqAddDeviceAlarm(data) {
      try {
        const response = await DeviceAPI.requestAddDeviceAlarm(data)
        if (response != null && response.data.result) {
          return true
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
})
