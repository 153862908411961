// 공통 기능 캡슐화
const commonMixin = {
  created() {
    // console.log("common mixin created");
  },
  methods: {
    dataChange: function () {
      this.countData++
    },
    checkIdReg: function (id) {
      const reg = /^[a-z]+[a-z0-9]{5,19}$/g
      return reg.test(id)
    },
    checkPwReg: function (pw) {
      const reg = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/
      return reg.test(pw)
    },
    checkEmailReg: function (email) {
      const reg = /^[0-9a-zA-Z]([-_\\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i
      return reg.test(email)
    },
    checkPhonReg: function (phon) {
      const regExp = /^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-\d{4}$/
      return regExp.test(phon)
    },
    checkNull(value) {
      return value !== null && value !== undefined && value !== ''
    },
    formatDateToYYYYMMDD(date) {
      if (typeof date === 'string') return date
      if (date === null) return
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')

      return `${year}-${month}-${day}`
    },
    formatDateToYYYYMMDD_HH_MM_SS(date) {
      if (typeof date === 'string') return date
      if (date === null) return
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      const seconds = String(date.getSeconds()).padStart(2, '0')

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    },
    getTodayYYYYMMDD() {
      const today = new Date()

      const year = today.getFullYear()
      const month = ('0' + (today.getMonth() + 1)).slice(-2)
      const day = ('0' + today.getDate()).slice(-2)

      return year + '-' + month + '-' + day
    },
    getTodayYYYYMMDDHHmmSS() {
      const today = new Date()

      const year = today.getFullYear()
      const month = ('0' + (today.getMonth() + 1)).slice(-2)
      const day = ('0' + today.getDate()).slice(-2)

      const hours = today.getHours()
      const minutes = today.getMinutes()
      const seconds = today.getSeconds()
      const milliseconds = today.getMilliseconds()

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`
    },
    getMonthAgoYYYYMMDD() {
      const today = new Date()

      const year = today.getFullYear()
      const month = ('0' + (today.getMonth() - 2)).slice(-2)
      const day = ('0' + today.getDate()).slice(-2)

      return year + '-' + month + '-' + day
    },
    formatCheckPort(str) {
      return !isNaN(str) && Number(str) >= 0 && Number(str) <= 65535
    },
    formatCheckIP(str) {
      const pattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
      return pattern.test(str)
    },
    checkAllTrue(obj) {
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          if (!obj[key]) {
            return false
          }
        }
      }
      return true
    },
    isEmpty(value) {
      return value === '' || value == null || (typeof value == 'object' && !Object.keys(value).length)
    },
  },
  data() {
    return {
      countData: 0,
      title: '믹스인 타이틀',
    }
  },
}

export default commonMixin
