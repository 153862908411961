<template>
  <div class="main_wrap">
    <div class="left-div">
      <div class="device_list_wrap">
        <div v-for="(item, index) in dashBoardItemList" :key="index" class="dash_item" :class="{ on: item.deviceID === selectedDashItemID }" @click="clickDeviceItem(index)">
          <div class="isError" :class="{ on: item.isError > 0 && isErrorActive[index] }"></div>
          <p class="title">
            {{ item.name }}
            <span style="display: flex; align-items: center">
              <template v-if="item.deviceModel === '100101'">
                <span v-if="item.viewData3 === '1'" class="state on">운행</span>
                <span v-else class="state">정지</span>
              </template>

              <img v-if="item.linkStatus" class="link_state" :src="require('@/assets/images/ico_link_success.png')" alt="" />
              <img v-else class="link_state" :src="require('@/assets/images/ico_link_fail.png')" alt="" />
            </span>
          </p>
          <template v-if="item.deviceModel === '100101'">
            <div class="view-data-box">
              <span style="color: #e3d294">{{ item.viewData1 / 10 }}</span> ℃ <span style="margin-left: 30px; color: #84c8c0">{{ item.viewData2 }}</span> %
            </div>
            <div class="state_box">
              <span class="state" :class="{ on: item.viewData4 === '1' }">팬</span>
              <span class="state" :class="{ on: item.viewData5 === '1' }">냉방</span>
              <span class="state" :class="{ on: item.viewData6 === '1' }">난방</span>
              <span class="state" :class="{ on: item.viewData7 === '1' }">가습</span>
              <span class="state" :class="{ on: item.viewData8 === '1' }">제습</span>
            </div>
          </template>
          <template v-if="item.deviceModel === '100201'">
            <div class="view-data-box">
              <span style="color: #e3d294">{{ item.viewData1 / 10 }}</span> ℃ <span style="margin-left: 30px; color: #84c8c0">{{ item.viewData2 }}</span> %
            </div>
          </template>
          <template v-if="item.deviceModel === '100301'">
            <div class="view-data-box" style="font-size: 30px; margin-bottom: 0">
              <span style="color: #e3d294;"> 룸온도 : {{ item.viewData1 / 10 }} </span> ℃ <span style="margin-left: 30px; color: #e3d294"> 과열도 : {{ item.viewData2 }} </span> %
            </div>
            <div class="view-data-box" style="font-size: 30px; margin-bottom: 0">
              <span style="color: #84c8c0">고압 : {{ item.viewData3 / 10 }}</span> bar <span style="margin-left: 30px; color: #84c8c0">저압 : {{ item.viewData4 }}</span> bar
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="right-div">
      <div class="top-div">
        <p class="title"># 대시보드 - {{ title }}</p>
      </div>
      <div class="bottom-div">
        <div class="btn-div">
          <button class="function_btn" :class="{ on: dataShowOption === 'Config' }" @click="showConfig">
            <span>감시화면</span>
          </button>
          <button class="function_btn" :class="{ on: dataShowOption === 'Trend' }" @click="showTrend">
            <span>트랜드</span>
          </button>
          <button class="function_btn" :class="{ on: dataShowOption === 'History' }" @click="showHistory">
            <span>경보이력</span>
          </button>
        </div>

        <ConfigDevice v-if="dataShowOption === 'Config'" :item-i-d="selectedDashItemID" :device-type="selectedItemModel" />
        <TrendData v-if="dataShowOption === 'Trend'" :item-i-d="selectedDashItemID" :device-type="selectedItemModel" />
        <HistoryData v-if="dataShowOption === 'History'" :item-i-d="selectedDashItemID" :device-type="selectedItemModel" />
      </div>
    </div>
  </div>
</template>

<script>
import { dashStore } from '@/store/dash.store'
import commonMixin from '@/mixin/commonMixin'
import { commonStore } from '@/store/common.store'
import ConfigDevice from '@/components/pc/dashboard/config/ConfigData.vue'
import TrendData from '@/components/pc/dashboard/trend/TrendData.vue'
import HistoryData from '@/components/pc/dashboard/history/HistoryData.vue'

export default {
  name: 'DashMain',
  components: { TrendData, ConfigDevice, HistoryData },
  mixins: [commonMixin],
  setup() {
    const useStoreDash = dashStore()
    const useCommonStore = commonStore()
    return { useStoreDash, useCommonStore }
  },
  data() {
    return {
      title: '',
      selectedDashItemID: 0,
      selectedItemModel: '',

      dashBoardItemList: null,
      connectFlag: false,
      alarmFlag: false,
      dataShowOption: 'Config',

      isErrorActive: {},
    }
  },
  mounted() {
    this.getDashBoardData()
    this.dataInterval = setInterval(() => {
      this.getDashBoardData()
    }, 7000)
  },
  beforeUnmount() {
    clearInterval(this.errorToggleInterval)
    clearInterval(this.dataInterval)
  },
  methods: {
    startErrorToggle(index) {
      this.isErrorActive = {
        ...this.isErrorActive,
        [index]: true,
      }
      this.errorToggleInterval = setInterval(() => {
        this.isErrorActive = {
          ...this.isErrorActive,
          [index]: !this.isErrorActive[index],
        }
      }, 500)
    },
    stopErrorToggle(index) {
      if (this.errorToggleInterval) {
        clearInterval(this.errorToggleInterval)
        this.errorToggleInterval = null
      }
      this.isErrorActive = {
        ...this.isErrorActive,
        [index]: false,
      }
    },
    getDashBoardData() {
      this.useStoreDash.reqDashBoardDeviceList().then((result) => {
        if (result) {
          this.dashBoardItemList = this.useStoreDash.getDashBoardItemList
          if (this.selectedDashItemID === 0) {
            this.selectedDashItemID = this.dashBoardItemList[0].deviceID
            this.title = this.dashBoardItemList[0].name
            this.selectedItemModel = this.dashBoardItemList[0].deviceModel
          }

          this.dashBoardItemList.forEach((item, index) => {
            this.stopErrorToggle(index)
          })

          this.dashBoardItemList.forEach((item, index) => {
            if (item.isError > 0) this.startErrorToggle(index)
          })
        }
        this.useCommonStore.stopLoading()
      })
    },
    clickDeviceItem(index) {
      this.selectedDashItemID = this.dashBoardItemList[index].deviceID
      this.title = this.dashBoardItemList[index].name
      this.selectedItemModel = this.dashBoardItemList[index].deviceModel
      this.dataShowOption = 'Config'
    },
    showConfig() {
      this.dataShowOption = 'Config'
    },
    showTrend() {
      this.dataShowOption = 'Trend'
    },
    showHistory() {
      this.dataShowOption = 'History'
    },
  },
}
</script>
