<template>
  <div class="info-wrap">
    <p>검색 조건</p>

    <div class="container" style="align-items: center">
      <div class="box" style="flex: none; margin-right: 40px">
        <div class="search_date_form">
          <VueDatePicker v-model="searchStartDate" class="search_date" placeholder="검색 시작 날짜" format="yyyy-MM-dd HH:mm:ss" />
          <VueDatePicker v-model="searchEndDate" class="search_date" placeholder="검색 종료 날짜" format="yyyy-MM-dd HH:mm:ss" />
        </div>
      </div>
      <div class="box" style="flex: none; margin-right: 80px">
        <label style="margin-right: 30px; display: inline-block; width: 90px;">작업자 명</label>
        <select v-model="selected1" style="width: 240px;">
          <option value="">작업자 명</option>
          <option v-for="(item, index) in selected1List" :key="index" :value="item.id">{{ item.name }}</option>
        </select>
        <br>
        <label style="margin-right: 30px; display: inline-block; width: 90px;">장비명</label>
        <select v-model="selected2" style="width: 240px; margin-top: 20px;">
          <option value="">장비명</option>
          <option v-for="(item, index) in selected2List" :key="index" :value="item.id">{{ item.name }}</option>
        </select>
      </div>
      <div class="box">
        <button class="blue default_btn" style="width: 130px" @click="search">
          <span>검색</span>
        </button>
        <br />
        <button class="grey default_btn" style="margin-top: 14px; width: 130px" @click="exportExcel">
          <span>엑셀 출력</span>
        </button>
      </div>
    </div>
  </div>
  <div class="info-wrap">
    <CommonTable :item-option="itemOption" :item-list="historyList" :page-info="pageInfo" @row-clicked="handleRowClick" />
    <CommonPaging v-if="historyList.length !== 0" :page-info="pageInfo" @move-page="handlePage" />
  </div>
</template>
<script>
import commonMixin from '@/mixin/commonMixin'
import { dashStore } from '@/store/dash.store'
import { commonStore } from '@/store/common.store'
import CommonPaging from '@/components/pc/common/CommonPaging.vue'
import CommonTable from '@/components/pc/common/CommonTable.vue'

export default {
  components: { CommonTable, CommonPaging },
  mixins: [commonMixin],
  setup() {
    const useStoreDash = dashStore()
    const useCommonStore = commonStore()

    return { useStoreDash, useCommonStore }
  },
  data() {
    return {
      searchStartDate: '',
      searchEndDate: '',

      selected1: '',
      selected2: '',
      selected1List: [],
      selected2List: [],

      page: 1,
      pageInfo: [],
      historyList: [],
      itemOption: [
        {name: 'NO', width: '4%', target: 'no'},
        {name: '장비명', width: '20%', target: 'name'},
        {name: '날짜', width: '10%', target: 'createdTime'},
        {name: '시간', width: '10%', target: 'releaseTime'},
        {name: '작업내용', width: '10%', target: 'releaseTime'},
        {name: '작업자', width: '10%', target: 'releaseTime'},
      ],
    }
  },
  mounted() {
  },
  methods: {
    search(e) {
      e.preventDefault()
    },
    exportExcel(e) {
      e.preventDefault()
    },
    handlePage(data) {
      console.log(data)
    },
    handleRowClick(rowIndex) {
      console.log(rowIndex)
    },
  },
}
</script>
