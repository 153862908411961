import axios from '@/utils/axios_interceptor'

function requestSignUp(memberForm) {
  return axios.post('/member/join', memberForm)
}

function requestLogin(memberForm) {
  return axios.post('/member/login', memberForm)
}

function requestLogout() {
  return axios.delete('/member/logout')
}

function requestReissueToken() {
  return axios.post('/member/reissueToken')
}

function requestPasswordChange(memberForm) {
  return axios.post('/member/passwordChange', memberForm)
}

function requestMemberList(page) {
  return axios.get('/member/getMemberList?page=' + page)
}

function requestMemberListAll() {
  return axios.get('/member/getMemberListAll')
}

function requestMemberInfoChangeAdmin(memberForm) {
  return axios.post('/member/memberInfoChangeAdmin', memberForm)
}

export { requestSignUp, requestLogin, requestLogout, requestReissueToken, requestPasswordChange, requestMemberList, requestMemberInfoChangeAdmin, requestMemberListAll }
