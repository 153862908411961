<template>
  <div class="modal">
    <div class="overlay" @click="$emit('close-modal')" />
    <div class="modal-content device">
      <form>
        <p v-if="selectedItem !== null" class="title">장비 수정</p>
        <p v-else class="title">장비 추가</p>

        <div class="btn-div">
          <button class="function_btn" :class="{ on: showOption === 0 }" @click.prevent="changeTab(0)">
            <span>기본 정보</span>
          </button>
          <button class="function_btn" :class="{ on: showOption === 1 }" @click.prevent="changeTab(1)">
            <span>통신 설정</span>
          </button>
          <button v-if="selectedItem !== null" class="function_btn" :class="{ on: showOption === 2 }" @click.prevent="changeTab(2)">
            <span>알람 메세지 설정</span>
          </button>
          <button class="function_btn" :class="{ on: showOption === 3 }" @click.prevent="changeTab(3)">
            <span>기타 설정</span>
          </button>
        </div>
        <img class="exit-btn" alt="이전 페이지" src="" @click="closeModal" />

        <!-- 기본 설정 -->
        <div v-if="showOption === 0" style="margin-top: 30px">
          <table>
            <tr>
              <td>
                <label for="id">통신 ID</label>
                <input id="id" v-model="id" type="number" placeholder="통신 ID" autocomplete="off" maxlength="16" :disabled="selectedItem !== null" :class="{ error: !error.id }" />
              </td>
              <td>
                <label for="name">명칭</label>
                <input id="name" v-model="name" type="text" placeholder="명칭" maxlength="40" :class="{ error: !error.name }" />
              </td>
            </tr>
            <tr>
              <td>
                <label for="deviceType">장비 종류</label>
                <select id="deviceType" v-model="deviceType" :class="{ error: !error.deviceType }" :disabled="selectedItem !== null">
                  <option value="">장비 종류</option>
                  <option v-for="(item, index) in deviceTypeList" :key="index" :value="item.id">{{ item.name }}</option>
                </select>
              </td>
              <td>
                <label for="deviceModel">장비 모델</label>
                <select id="deviceModel" v-model="deviceModel" :class="{ error: !error.deviceType }" :disabled="selectedItem !== null">
                  <option value="">장비 모델</option>
                  <option v-for="(item, index) in deviceModelList" :key="index" :value="item.id">{{ item.name }}</option>
                </select>
              </td>
            </tr>

            <tr>
              <td>
                <label>장비 위치</label>
                <input ref="et_location" v-model="location" type="text" placeholder="장비 위치" maxlength="40" />
              </td>
              <td>
                <label>설치 일자</label>
                <div style="display: inline-block; width: 270px">
                  <VueDatePicker v-model="setupDate" placeholder="설치 일자" format="yyyy-MM-dd HH:mm" />
                </div>
              </td>
            </tr>
          </table>
        </div>

        <!-- 통신 설정 -->
        <div v-if="showOption === 1" style="margin-top: 30px">
          <table>
            <tr>
              <td>
                <label for="c_type">통신 타입</label>
                <select id="c_type" v-model="c_type" :class="{ error: !error.c_type }">
                  <option value="">통신 타입</option>
                  <option value="tcp">TCP</option>
                  <option value="rtu">RTU</option>
                </select>
              </td>
              <td>
                <label>명령 재전송 횟수</label>
                <select id="resubmitNo" v-model="resubmitNo" :class="{ error: !error.c_type }">
                  <option value="">명령 재전송 횟수</option>
                  <option value="0">0</option>
                  <option value="3">3</option>
                  <option value="5">5</option>
                  <option value="10">10</option>
                </select>
              </td>
            </tr>

            <tr>
              <td>
                <label>통신 시간초과</label>
                <select id="timeOut" v-model="timeOut" :class="{ error: !error.c_type }">
                  <option value="">통신 시간초과</option>
                  <option value="5000">5초</option>
                  <option value="10000">10초</option>
                  <option value="15000">15초</option>
                </select>
              </td>
              <td>
                <label>통신 지연시간</label>
                <select id="delayTime" v-model="delayTime" :class="{ error: !error.c_type }">
                  <option value="">통신 지연시간</option>
                  <option value="5000">5초</option>
                  <option value="10000">10초</option>
                  <option value="15000">15초</option>
                </select>
              </td>
            </tr>
            <tr v-if="c_type === 'tcp'">
              <td>
                <label>아이피</label>
                <input v-model="ip" type="text" placeholder="IP" autocomplete="off" maxlength="16" :class="{ error: !error.ip }" />
              </td>
              <td>
                <label>포트</label>
                <input v-model="port" type="number" placeholder="Port" autocomplete="off" maxlength="16" :class="{ error: !error.port }" />
              </td>
            </tr>

            <tr v-if="c_type === 'rtu'">
              <td>
                <label>통신 속도</label>
                <input v-model="cSpeed" type="text" placeholder="통신 속도" autocomplete="off" maxlength="16" :class="{ error: !error.ip }" />
              </td>
              <td>
                <label>정지 비트</label>
                <input v-model="stopBit" type="number" placeholder="정지 비트" autocomplete="off" maxlength="16" :class="{ error: !error.port }" />
              </td>
            </tr>

            <tr v-if="c_type === 'rtu'">
              <td colspan="2">
                <label>채널</label>
                <input v-model="channel" type="text" placeholder="채널" autocomplete="off" maxlength="16" :class="{ error: !error.ip }" />
              </td>
            </tr>
          </table>
        </div>

        <!-- 알림 메세지 설정 -->
        <div v-if="showOption === 2" style="margin-top: 30px">
          <table>
            <colgroup>
              <col style="width: 50px" />
              <col style="width: 50px" />
              <col />
              <col style="width: 60px" />
              <col style="width: 60px" />
            </colgroup>
            <tr>
              <th>적용</th>
              <th>No</th>
              <th>내용</th>
              <th>최소</th>
              <th>최대</th>
            </tr>
          </table>
          <div style="height: 300px; overflow-y: auto">
            <table>
              <colgroup>
                <col style="width: 50px" />
                <col style="width: 50px" />
                <col />
                <col style="width: 60px" />
                <col style="width: 60px" />
              </colgroup>
              <tr v-for="(item, index) in alarmList" :key="index" class="item" @click="checkItem(index)">
                <td style="text-align: center"><input v-model="item.active" type="checkbox" style="width: auto" /></td>
                <td style="text-align: center">{{ index + 1 }}</td>
                <td style="text-align: center">{{ item.protocolName }}</td>
                <td style="text-align: center">{{ item.minValue }}</td>
                <td style="text-align: center">{{ item.maxValue }}</td>
              </tr>
            </table>
          </div>
        </div>

        <!-- 기타 설정 -->
        <div v-if="showOption === 3" style="margin-top: 30px">
          <table>
            <tr>
              <td colspan="2">
                <label>우선 순위</label>
                <input v-model="priority" type="number" placeholder="우선순위 ( 1 ~ 99 )" autocomplete="off" maxlength="16" :class="{ error: !error.priority }" />
              </td>
            </tr>
            <tr>
              <td>
                <label>데이터 저장기간</label>
                <input v-model="dataPeriod" type="number" placeholder="장비 데이터 저장기간(일)" autocomplete="off" maxlength="16" :class="{ error: !error.priority }" />
              </td>
              <td>
                <label>이력 저장기간</label>
                <input v-model="historyPeriod" type="number" placeholder="장비 데이터 저장기간(일)" autocomplete="off" maxlength="16" :class="{ error: !error.priority }" />
              </td>
            </tr>
          </table>
        </div>

        <p class="error_txt" v-html="error_text" />

        <div class="button-wrap" style="margin-top: 30px">
          <button v-if="selectedItem === null" class="blue default_btn" @click="addDevice">
            <span>추가</span>
          </button>
          <button v-if="selectedItem !== null" class="blue default_btn" @click="modifyDevice">
            <span>수정</span>
          </button>
          <button v-if="selectedItem !== null" class="red default_btn" @click="deleteDevice">
            <span>장비 삭제</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import commonMixin from '@/mixin/commonMixin'
import { deviceStore } from '@/store/device.store'

export default defineComponent({
  mixins: [commonMixin],
  props: {
    selectedItem: {
      type: Object,
      default: null,
    },
    deviceTypeList: {
      type: Array,
      default: null,
    },
  },
  emits: ['close-modal', 'change-success', 'alarm-modal'],
  setup(props) {
    const computedValue = computed(() => {
      return props.selectedItem
    })

    const useStoreDevice = deviceStore()
    return { useStoreDevice, computedValue }
  },
  data() {
    return {
      showOption: 0,
      deviceModelList: [],
      isFirst: true,

      // 기본정보
      id: '',
      name: '',
      deviceType: '',
      deviceModel: '',
      location: '',
      setupDate: '',

      // 통신설정
      c_type: '',

      timeOut: '',
      delayTime: '',
      resubmitNo: '',

      // tcp
      ip: '',
      port: '',

      // rtu
      stopBit: '',
      cSpeed: '',
      channel: '',

      // 기타 설정
      priority: 0,
      historyPeriod: '',
      dataPeriod: '',

      error_text: '',
      alarmList: [],

      error: {
        id: true,
        name: true,
        deviceType: true,
        c_type: true,
        ip: true,
        port: true,
        serialNumber: true,
        priority: true,
      },
    }
  },
  watch: {
    deviceType() {
      if (this.deviceType === '') {
        this.deviceModel = ''
        this.deviceModelList = []
      } else {
        this.getModelList(this.deviceType)
      }
    },
  },
  mounted() {
    if (this.computedValue) {
      this.id = this.computedValue.id
      this.name = this.computedValue.name
      this.deviceType = this.computedValue.deviceType
      this.deviceModel = this.computedValue.deviceModel
      this.location = this.computedValue.location
      this.setupDate = this.computedValue.setupDate

      this.c_type = this.computedValue.c_type
      this.timeOut = this.computedValue.timeOut
      this.delayTime = this.computedValue.delayTime
      this.resubmitNo = this.computedValue.resubmitNo

      this.ip = this.computedValue.ip
      this.port = this.computedValue.port

      this.stopBit = this.computedValue.stopBit
      this.cSpeed = this.computedValue.cSpeed
      this.channel = this.computedValue.channel

      this.priority = this.computedValue.priority
      this.dataPeriod = this.computedValue.dataPeriod
      this.historyPeriod = this.computedValue.historyPeriod
    }
    this.getAlarmList()
  },
  methods: {
    addDevice(e) {
      e.preventDefault()
      if (!this.checkValue()) return

      const deviceData = {
        id: this.id,
        name: this.name,
        deviceType: this.deviceType,
        deviceModel: this.deviceModel,
        location: this.location,
        setupDate: this.formatDateToYYYYMMDD_HH_MM_SS(this.setupDate),

        c_type: this.c_type,
        timeOut: this.timeOut,
        delayTime: this.delayTime,
        resubmitNo: this.resubmitNo,

        ip: this.ip,
        port: this.port,

        stopBit: this.stopBit,
        cSpeed: this.cSpeed,
        channel: this.channel,

        priority: this.priority,
        dataPeriod: this.dataPeriod,
        historyPeriod: this.historyPeriod,
      }

      this.useStoreDevice.reqAddDevice(deviceData).then((result) => {
        if (result) {
          alert('장비가 추가 되었습니다.')
          this.$emit('change-success')
          this.$emit('close-modal')
        }
      })
    },
    changeTab(num) {
      if (num === 2 && this.deviceModel === '') {
        alert('장비 모델을 먼저 선택 해 주세요.')
        return
      }
      this.showOption = num
    },
    getAlarmList() {
      if (this.id === '') return
      const deviceData = {
        id: this.id,
        deviceModel: this.deviceModel,
      }
      this.useStoreDevice.reqGetAlarmList(deviceData).then((r) => {
        if (r) this.alarmList = this.useStoreDevice.getAlarmList
      })
    },
    getModelList(code) {
      this.useStoreDevice.reqDeviceModelList(code).then((result) => {
        if (result) {
          this.deviceModelList = this.useStoreDevice.getDeviceModelList
          if (this.isFirst) this.isFirst = false
          else this.deviceModel = ''
        }
      })
    },
    modifyDevice(e) {
      e.preventDefault()
      if (!this.checkValue()) return

      const alarmList = this.alarmList.filter((item) => item.active === true).map((item) => item.protocolId)
      const deviceData = {
        id: this.id,
        name: this.name,
        location: this.location,
        setupDate: this.formatDateToYYYYMMDD_HH_MM_SS(this.setupDate),

        c_type: this.c_type,
        timeOut: this.timeOut,
        delayTime: this.delayTime,
        resubmitNo: this.resubmitNo,

        ip: this.ip,
        port: this.port,

        stopBit: this.stopBit,
        cSpeed: this.cSpeed,
        channel: this.channel,

        priority: this.priority,
        dataPeriod: this.dataPeriod,
        historyPeriod: this.historyPeriod,
        alarmList: alarmList,
      }

      this.useStoreDevice.reqModifyDevice(deviceData).then((result) => {
        if (result) {
          alert('장비가 수정 되었습니다.')
          this.$emit('change-success')
          this.$emit('close-modal')
        }
      })
    },
    deleteDevice(e) {
      e.preventDefault()
      if (!confirm('※ 해당 작업 수행 시, 모든 장비의 데이터가 삭제 됩니다.\n실행 하시겠습니까?')) return
      const deviceData = {
        id: this.id,
      }
      this.useStoreDevice.reqDeleteDevice(deviceData).then((result) => {
        if (result) {
          alert('장비가 삭제 되었습니다.')
          this.$emit('change-success')
          this.$emit('close-modal')
        }
      })
    },
    alarmSetting(e) {
      e.preventDefault()
      this.$emit('alarm-modal')
    },
    closeModal(e) {
      e.preventDefault()
      this.$emit('close-modal')
    },
    checkValue() {
      this.error_text = ''
      this.error = {
        id: true,
        name: true,
        deviceType: true,
        c_type: true,
        ip: true,
        port: true,
        serial: true,
        serialNumber: true,
      }

      if (this.id === '') {
        this.error_text += '※ 통신 ID를 입력해 주세요.<br>'
        this.error.id = false
      }

      if (this.name === '') {
        this.error_text += '※ 장비 명칭을 입력해 주세요.<br>'
        this.error.name = false
      }

      if (this.deviceType === '') {
        this.error_text += '※ 장비 종류를 선택해 주세요.<br>'
        this.error.deviceType = false
      }

      if (this.c_type === 'tcp') {
        if (this.ip === '') {
          this.error_text += '※ IP 를 입력 해 주세요.<br>'
          this.error.ip = false
        } else {
          if (!this.formatCheckIP(this.ip)) {
            this.error_text += '※ 유효한 IP를 해 주세요.<br>'
            this.error.ip = false
          }
        }

        if (this.port === '') {
          this.error_text += '※ Port 를 입력 해 주세요.<br>'
          this.error.port = false
        } else {
          if (!this.formatCheckPort(this.port)) {
            this.error_text += '※ 유효한 Port 를 해 주세요.<br>'
            this.error.port = false
          }
        }
      }

      if (this.c_type === 'serial') {
        if (this.serialNumber === '' || this.serialNumber === null) {
          this.error_text += '※ 시리얼 번호를 입력 해 주세요.<br>'
          this.error.serialNumber = false
        }
      }

      return this.checkAllTrue(this.error)
    },
    checkItem(no) {
      this.alarmList[no].active = !this.alarmList[no].active
    },
  },
})
</script>
