<template>
  <div id="chart">
    <apexchart id="apex_chart" ref="realtimeChart" type="line" height="350" :options="chartOptions" :series="series" />
  </div>
</template>

<script>
import commonMixin from '@/mixin/commonMixin'

export default {
  mixins: [commonMixin],
  data: function () {
    return {
      v1List: [],
      v2List: [],

      series: [
        {
          name: '온도',
          data: [],
        },
        {
          name: '습도',
          data: [],
        },
      ],
      chartOptions: {
        colors: ['#DD6D88', '#437EF7'],
        chart: {
          background: '#22222E',
          height: 350,
          type: 'line',
          toolbar: {
            show: true,
            tools: {
              download: true,
              reset: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          show: false,
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          type: 'category',
          categories: [],
          labels: {
            style: {
              colors: '#fff',
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: '#fff',
            },
          },
        },
        legend: {
          labels: {
            colors: '#fff',
            useSeriesColors: false,
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
    }
  },
  methods: {
    dataChange(dataList) {
      if (dataList != null) {
        const tempCreated = dataList.map((item) => item.created_date)
        this.createdList1 = tempCreated.map((dateString) => {
          const date = new Date(dateString)
          return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`
        })

        this.v1List = dataList.map((item) => item.v1)
        this.v2List = dataList.map((item) => item.v2)

        this.series = [
          { name: '온도', data: this.v1List },
          { name: '습도', data: this.v2List },
        ]

        this.$refs.realtimeChart.updateOptions({ labels: this.createdList1 })
      }
    },
  },
}
</script>
