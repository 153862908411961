import { defineStore } from 'pinia'
import { requestDashBoardDeviceList, requestDashBoardDeviceConfig, requestDashBoardChart, requestGetTrendData } from '@/apis/dash_api'

export const dashStore = defineStore('dash', {
  state: () => ({
    dashBoardItemList: [],
    dashBoardChartItemList: [],
    dashBoardConfigData: null,
    selectedItemModel: null,
    selectedDashItemID: null,
    chartData: [],
    trendData: [],
  }),
  getters: {
    getSelectedDashItem: (state) => {
      return state.selectedDashItemID
    },
    getChartData: (state) => {
      return state.chartData
    },
    getDashBoardItemList: (state) => {
      return state.dashBoardItemList
    },
    getDashBoardConfigData: (state) => {
      return state.dashBoardConfigData
    },
    getDashBoardChartItemList: (state) => {
      return state.dashBoardChartItemList
    },
    getSelectedItemModel: (state) => {
      return state.selectedItemModel
    },
    getTrendData: (state) => {
      return state.trendData
    },
  },
  actions: {
    async reqDashBoardDeviceList() {
      try {
        const response = await requestDashBoardDeviceList()
        if (response != null && response.status === 200) {
          this.dashBoardItemList = response.data.data
          return response.data.result
        }
      } catch (e) {
        console.log(e)
      }
    },
    async reqGetTrendData(deviceID, startDate, endDate) {
      try {
        const response = await requestGetTrendData(deviceID, startDate, endDate)
        if (response.data.result) {
          this.trendData = response.data.data
          return true
        } else {
          return false
        }
      } catch (e) {
        console.log(e)
      }
    },
    async reqDashBoardDeviceConfig(deviceID) {
      try {
        const response = await requestDashBoardDeviceConfig(deviceID)

        if (response.data.result) {
          this.dashBoardConfigData = response.data.data
          this.chartData = response.data.subData
          return true
        } else {
          return false
        }
      } catch (e) {
        console.log(e)
      }
    },
    async reqDashBoardChart(deviceID, startDate, endDate) {
      const response = await requestDashBoardChart(deviceID, startDate, endDate)
      if (response.data.result) {
        this.dashBoardChartItemList = response.data.data
        return true
      } else {
        return false
      }
    },
    setSelectDashItem(itemID) {
      this.selectedDashItemID = itemID
    },
    setSelectItemModel(model) {
      this.selectedItemModel = model
    },
  },
})
