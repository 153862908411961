import { memberStore } from '@/store/member.store'
import DeviceSetting from '@/components/pc/setting/device/DeviceSetting.vue'
import MainView from '@/views/pc/Main.vue'
import SettingMain from "@/components/pc/setting/SettingMain.vue";
import UserSetting from "@/components/pc/setting/UserSetting.vue";


const onlyAuth = (to, from, next) => {
  const userStore = memberStore()
  if (userStore.isAuthenticated) next()
  else next('/login')
}
export default [
  {
    path: '/setting',
    redirect: '/setting/main',
    components: {
      app_router: MainView,
    },
    meta: {
      title: '설정',
    },
    children: [
      {
        path: 'main',
        beforeEnter: onlyAuth,
        components: {
          main_router: SettingMain,
        },
      },
      {
        path: 'device',
        beforeEnter: onlyAuth,
        components: {
          main_router: DeviceSetting,
        },
      },
      {
        path: 'user',
        beforeEnter: onlyAuth,
        components: {
          main_router: UserSetting,
        },
      },
    ],
  },
]
