<template>
  <div id="chart">
    <apexchart id="apex_chart" ref="realtimeChart" type="line" height="350" :options="chartOptions" :series="series" />
  </div>
</template>

<script>
import commonMixin from '@/mixin/commonMixin'

export default {
  mixins: [commonMixin],
  data: function () {
    return {
      series: [],
      chartOptions: {
        chart: {
          background: '#22222E',
          height: 350,
          type: 'line',
          toolbar: {
            show: true,
            tools: {
              download: true,
              reset: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          show: false,
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          type: 'category',
          categories: [],
          labels: {
            style: {
              colors: '#fff',
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: '#fff',
            },
          },
        },
        legend: {
          labels: {
            colors: '#fff',
            useSeriesColors: false,
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
    }
  },
  methods: {
    dataChange(dateList, seriesList, colorList) {
      this.series = seriesList
      this.$refs.realtimeChart.updateOptions({ labels: dateList, colors: colorList})
    },
  },
}
</script>
