<template>
  <div v-if="pageInfo" class="paging">
    <ul>
      <li class="prev" @click="movePage(pageInfo.pageIndex)"><img alt="이전 페이지" src="" /></li>
      <li v-for="index in numbersBetween(pageInfo.minPage, pageInfo.maxPage)" :key="index" :class="{ on: pageInfo.pageIndex + 1 === index }" @click="movePage(index)">
        {{ index }}
      </li>
      <li class="next" @click="movePage(pageInfo.pageIndex + 2)"><img alt="다음 페이지" src="" /></li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    pageInfo: {
      type: Object,
      require: false,
      default: null,
    },
  },
  emits: ['move-Page'],
  methods: {
    movePage(page) {
      if (page === 0 || page > this.pageInfo.totalPage) return;

      this.$emit('move-Page', page);
    },

    numbersBetween(start, end) {
      const result = [];
      for (let i = start; i <= end; i++) {
        result.push(i);
      }
      return result;
    },
  },
};
</script>
