import axios from '@/utils/axios_interceptor'

function requestDeviceTypeList() {
  return axios.get('/device/getDeviceTypeList')
}

function requestDeviceModelList(code) {
  return axios.get('/device/getDeviceModelList?code=' + code)
}

function requestDeviceListPaging(page) {
  return axios.get('/device/getDeviceListForPaging?page=' + page)
}

function requestAlertHistoryListPaging(page, deviceID) {
  return axios.get(`/dash/getAlertHistoryList?page=${page}&deviceID=${deviceID}`)
}

function requestDeviceList() {
  return axios.get('/device/getDeviceList')
}

function requestAddDevice(deviceData) {
  return axios.post('/device/addDevice', deviceData)
}

function requestModifyDevice(deviceData) {
  return axios.post('/device/modifyDevice', deviceData)
}

function requestAlarmList(deviceData) {
  return axios.post('/device/getDeviceAlarmList', deviceData)
}

function requestDeleteDevice(deviceData) {
  return axios.post('/device/deleteDevice', deviceData)
}

// DeviceService
function requestDeviceServiceLogList(startDate, endDate, deviceId, page) {
  return axios.get('/device/getDeviceServiceLogList?page=' + page + '&startDate=' + startDate + '&endDate=' + endDate + '&deviceId=' + deviceId)
}

function requestDeviceServiceLogExcel(startDate, endDate, deviceId) {
  return axios.get('/device/deviceServiceLogExcel?startDate=' + startDate + '&endDate=' + endDate + '&deviceId=' + deviceId, { responseType: 'blob' })
}

function requestAddDeviceServiceLog(deviceData) {
  return axios.post('/device/addDeviceServiceLog', deviceData)
}

function requestRemoveDeviceServiceLog(deviceData) {
  return axios.post('/device/deleteDeviceServiceLog', deviceData)
}

function requestAddDeviceAlarm(deviceData) {
  return axios.post('/device/addDeviceAlarm', deviceData)
}

function requestDeviceAuthList(id) {
  return axios.get('/device/getDeviceAuthListForMemberID?memberID=' + id)
}

function requestDeleteDeviceAuth(id) {
  return axios.delete('/device/deleteDeviceAuth?id=' + id)
}

function requestAddDeviceAuth(data) {
  return axios.post('/device/addDeviceAuth', data)
}

function requestChangeMwSetting(data) {
  return axios.post('/device/changeMwSetting', data)
}

export {
  requestDeviceTypeList,
  requestDeviceListPaging,
  requestDeviceList,
  requestAddDevice,
  requestModifyDevice,
  requestDeleteDevice,
  requestAlarmList,
  requestAddDeviceAlarm,
  requestDeviceServiceLogList,
  requestAddDeviceServiceLog,
  requestRemoveDeviceServiceLog,
  requestDeviceServiceLogExcel,
  requestDeviceModelList,
  requestDeviceAuthList,
  requestDeleteDeviceAuth,
  requestAddDeviceAuth,
  requestChangeMwSetting,
  requestAlertHistoryListPaging,
}
