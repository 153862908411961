<template>
  <LeftMenu />
  <router-view :key="$route.fullPath" class="main_content" name="main_router" />
</template>
<script>
import LeftMenu from '@/components/pc/common/MenuComponent.vue'

export default {
  name: 'MainView',
  components: { LeftMenu },
  data() {
    return {}
  },
  mounted() {},
  created() {},
  methods: {},
}
</script>
<style Lang="scss">
@import '@/assets/css/main.css';
</style>
