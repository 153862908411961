<template>
  <div class="info-wrap">
    <div class="bottom_wrap">
      <button class="blue default_btn add_btn" @click="deviceModal">
        <span>장비 추가+</span>
      </button>
      <CommonTable :item-option="itemOption" :item-list="deviceList" :page-info="pageInfo" @row-clicked="handleRowClick" />
      <CommonPaging v-if="deviceList.length !== 0" :page-info="pageInfo" @move-page="handlePage" />
    </div>

    <DeviceEditModal v-if="isModalViewed" :selected-item="selectedItem" :device-type-list="deviceTypeList" @close-modal="isModalViewed = false" @change-success="changeData" @alarm-modal="handleAlarmModal" />
    <DeviceAlarmModal v-if="isModalViewed && isModalViewed2" :selected-item="selectedItem" :device-type-list="deviceTypeList" @close-modal="isModalViewed2 = false" @change-success="changeData" />
  </div>
</template>
<script>
import CommonTable from '@/components/pc/common/CommonTable.vue';
import CommonPaging from '@/components/pc/common/CommonPaging.vue';
import { deviceStore } from '@/store/device.store';
import DeviceEditModal from '@/components/pc/setting/device/modal/DeviceEditModal.vue';
import DeviceAlarmModal from '@/components/pc/setting/modal/DeviceAlarmModal.vue';

export default {
  components: { DeviceAlarmModal, DeviceEditModal, CommonPaging, CommonTable },
  setup() {
    const useStoreDevice = deviceStore();
    return { useStoreDevice };
  },
  data() {
    return {
      page: 1,
      pageInfo: [],

      itemOption: [
        { name: 'NO', width: '4%', target: 'no' },
        { name: '명칭', width: '20%', target: 'name' },
        { name: '장비 종류', width: '10%', target: 'deviceTypeName' },
        { name: '장비 모델', width: '10%', target: 'deviceModelName' },
        { name: '장비 위치', width: '10%', target: 'location' },
        { name: '경보상태', width: '5%', target: 'alarmStatus', isStatus: true, trueValue: '정상', falseValue: '끊김' },
        { name: '통신상태', width: '5%', target: 'link_status', isStatus: true, trueValue: '정상', falseValue: '끊김' },
      ],
      deviceList: [],
      selectedItem: null,
      isModalViewed: false,
      isModalViewed2: false,
      deviceTypeList: [],
    };
  },
  mounted() {
    this.getDeviceTypeList();
    this.getDeviceList();
  },
  methods: {
    handleRowClick(rowIndex) {
      this.selectedItem = this.deviceList[rowIndex];
      this.isModalViewed = true;
    },
    handlePage(data) {
      if (this.page !== data) {
        this.page = data;
        this.getDeviceList();
      }
    },
    handleAlarmModal() {
      this.isModalViewed2 = true;
    },
    deviceModal() {
      this.selectedItem = null;
      this.isModalViewed = true;
    },
    getDeviceTypeList() {
      this.useStoreDevice.reqDeviceTypeList().then((r) => {
        if (r) this.deviceTypeList = this.useStoreDevice.getDeviceTypeList;
      });
    },
    getDeviceList() {
      this.useStoreDevice.reqDeviceListPaging(this.page).then((r) => {
        if (r) {
          this.deviceList = this.useStoreDevice.getDeviceList;
          this.pageInfo = this.useStoreDevice.getPageInfo;
          this.page = this.useStoreDevice.page;
        }
      });
    },
    changeData() {
      this.page = 1;
      this.getDeviceList();
    },
  },
};
</script>
