<template>
  <div>
    <div class="server-info-wrap" style="margin-right: 30px; float: left">
      <p class="title">CPU</p>
      <hr />
      <table>
        <tr>
          <td style="width: 200px;">코어 수</td>
          <td v-if="serverInfo">{{ serverInfo.totalRam }}</td>
        </tr>
        <tr>
          <td>사용량</td>
          <td v-if="serverInfo">{{ serverInfo.usedRam }}</td>
        </tr>
        <tr>
          <td>시스템 사용률</td>
          <td v-if="serverInfo">{{ serverInfo.emptyRam }}</td>
        </tr>
        <tr>
          <td>현재 공실률</td>
          <td v-if="serverInfo">{{ serverInfo.usedRamPer }}</td>
        </tr>
      </table>
    </div>
    <div class="server-info-wrap">
      <p class="title">RAM</p>
      <hr />
      <table>
        <tr>
          <td style="width: 200px;">총메모리</td>
          <td v-if="serverInfo">{{ serverInfo.totalRam }}</td>
        </tr>
        <tr>
          <td>사용량</td>
          <td v-if="serverInfo">{{ serverInfo.usedRam }}</td>
        </tr>
        <tr>
          <td>남은 메모리</td>
          <td v-if="serverInfo">{{ serverInfo.emptyRam }}</td>
        </tr>
        <tr>
          <td>사용률</td>
          <td v-if="serverInfo">{{ serverInfo.usedRamPer }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import commonMixin from '@/mixin/commonMixin'
import { memberStore } from '@/store/member.store'
import { commonStore } from '@/store/common.store'

export default {
  mixins: [commonMixin],
  setup() {
    const useStoreMember = memberStore()
    const useStoreCommon = commonStore()
    return { useStoreMember, useStoreCommon }
  },
  data() {
    return {
      serverInfo: null,
    }
  },
  mounted() {
    this.getServerInfo()
  },
  methods: {
    getServerInfo() {
      this.useStoreCommon.reqGetServerInfo().then((r) => {
        if (r) this.serverInfo = this.useStoreCommon.getServerInfo
      })
    },
  },
}
</script>
