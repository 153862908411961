<template>
  <div class="info-wrap">
    <p>검색 조건</p>

    <div class="container" style="align-items: center">
      <div class="box" style="flex: none; margin-right: 40px">
        <div class="search_date_form">
          <VueDatePicker v-model="searchStartDate" class="search_date" placeholder="검색 시작 날짜" format="yyyy-MM-dd HH:mm:ss" />
          <VueDatePicker v-model="searchEndDate" class="search_date" placeholder="검색 종료 날짜" format="yyyy-MM-dd HH:mm:ss" />
        </div>
      </div>
      <div class="box" style="flex: none; margin-right: 80px">
        <div class="checkbox-container">
          <label>
            <input v-model="isV1check" type="checkbox" /> 실내 온도
            <span class="color-box" style="background-color: #DD6D88"></span>
          </label>
          <label>
            <input v-model="isV2check" type="checkbox"/> 실내 습도
            <span class="color-box" style="background-color: #437EF7"></span>
          </label>
          <!--          <label>
            <input type="checkbox" /> SA 온도
            <span class="color-box" style="background-color: green"></span>
          </label>
          <label>
            <input type="checkbox" /> SA 습도
            <span class="color-box" style="background-color: yellow"></span>
          </label>-->
        </div>
      </div>
      <div class="box">
        <button class="blue default_btn" style="width: 130px" @click="search">
          <span>검색</span>
        </button>
        <br />
        <button class="grey default_btn" style="margin-top: 14px; width: 130px" @click="exportExcel">
          <span>엑셀 출력</span>
        </button>
      </div>
    </div>
  </div>
  <div class="info-wrap">
    <AreaChart ref="chartRef" :item="null" />
  </div>
</template>
<script>
import commonMixin from '@/mixin/commonMixin'
import { dashStore } from '@/store/dash.store'
import { commonStore } from '@/store/common.store'
import AreaChart from '@/components/pc/dashboard/chart/AreaTrendChart.vue'

export default {
  components: { AreaChart },
  mixins: [commonMixin],
  props: {
    itemID: {
      type: [Number, String],
      required: true,
    },
    deviceType: {
      type: [Number, String],
      required: true,
    },
  },
  setup() {
    const useStoreDash = dashStore()
    const useCommonStore = commonStore()

    return { useStoreDash, useCommonStore }
  },
  data() {
    return {
      tendDataList: [],
      dashBoardConfigData: null,
      searchStartDate: '',
      searchEndDate: '',

      isV1check: true,
      isV2check: true
    }
  },
  mounted() {},
  methods: {
    search(e) {
      e.preventDefault()

      if (this.searchStartDate ==='') {
        alert('시작일을 선택해 주세요.')
        return
      }
      if (this.searchEndDate ==='') {
        alert('종료일을 선택해 주세요.')
        return
      }

      const startDate = this.formatDateToYYYYMMDD_HH_MM_SS(this.searchStartDate)
      const endDate = this.formatDateToYYYYMMDD_HH_MM_SS(this.searchEndDate)

      this.useStoreDash.reqGetTrendData(this.itemID, startDate, endDate).then((r) => {
        if (r) {
          this.tendDataList = this.useStoreDash.getTrendData
          this.drawChart()
        }
      })
    },
    exportExcel(e) {
      e.preventDefault()
    },

    drawChart() {
      const compress_list = this.compressedArrList();

      const tempCreated = compress_list.map((item) => item.createdDate)
      const createdList = tempCreated.map((dateString) => {
        const date = new Date(dateString)
        return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`
      })

      let series = []
      let colors = []
      if (this.isV1check){
        series.push({ name: '실내 온도', data: compress_list.map((item) => item.v1) })
        colors.push('#DD6D88')
      }
      if (this.isV2check){
        series.push( { name: '실내 습도', data: compress_list.map((item) => item.v2) })
        colors.push('#437EF7')
      }


      this.$refs.chartRef.dataChange(createdList, series, colors)
    },

    compressedArrList() {
      if (this.tendDataList.length <= 100) {
        return this.tendDataList;
      }

      const compressed = [];
      const length = this.tendDataList.length;
      const step = Math.ceil(length / 100);

      for (let i = 0; i < length; i += step) {
        compressed.push(this.tendDataList[i]);
      }

      return compressed.slice(0, 100);
    }
  },
}
</script>
