<template>
  <div>
    <DetailType100101 v-if="deviceType === '100101'" :config-data="dashBoardConfigData" :chart-data="chartData" />
    <DetailType100201 v-if="deviceType === '100201'" :config-data="dashBoardConfigData" :chart-data="chartData" />
    <DetailType100301 v-if="deviceType === '100301'" :config-data="dashBoardConfigData" :chart-data="chartData" />
  </div>
</template>

<script>
import commonMixin from '@/mixin/commonMixin'
import { dashStore } from '@/store/dash.store'
import { commonStore } from '@/store/common.store'
import DetailType100101 from '@/components/pc/dashboard/config/typedetail/DetailType100101.vue'
import DetailType100201 from '@/components/pc/dashboard/config/typedetail/DetailType100201.vue'
import DetailType100301 from '@/components/pc/dashboard/config/typedetail/DetailType100301.vue'

export default {
  components: { DetailType100101, DetailType100201, DetailType100301 },
  mixins: [commonMixin],
  props: {
    itemID: {
      type: [Number, String],
      required: true,
    },
    deviceType: {
      type: [Number, String],
      required: true,
    },
  },
  setup() {
    const useStoreDash = dashStore()
    const useCommonStore = commonStore()

    return { useStoreDash, useCommonStore }
  },
  data() {
    return {
      dashBoardConfigData: null,
      chartData: null,
    }
  },
  watch: {
    itemID() {
      if (this.itemID !== 0) this.getDashBoardDetailData()
    },
  },
  mounted() {
    this.getDashBoardDetailData()
    this.dataInterval = setInterval(() => {
      this.getDashBoardDetailData()
    }, 10000)
  },
  unmounted() {
    clearInterval(this.dataInterval)
  },
  methods: {
    async getDashBoardDetailData() {
      await this.useStoreDash.reqDashBoardDeviceConfig(this.itemID).then((result) => {
        if (result) {
          this.dashBoardConfigData = this.useStoreDash.getDashBoardConfigData
          this.chartData = this.useStoreDash.getChartData
        } else this.dashBoardConfigData = null
      })
    },
  },
}
</script>
